import { render, staticRenderFns } from "./purchase.vue?vue&type=template&id=53a5d3bf&scoped=true"
import script from "./purchase.vue?vue&type=script&lang=js"
export * from "./purchase.vue?vue&type=script&lang=js"
import style0 from "./purchase.vue?vue&type=style&index=0&id=53a5d3bf&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53a5d3bf",
  null
  
)

export default component.exports
<template>
	<!-- star -->

	<div class="youjiaopage_wrap">
		<nav2></nav2>
		<banner2 :bannerH="h1" :bannerP="P1" :bannerImg="Img1"></banner2>
		<!-- 面包屑 -->
		<div class="Crumbs">
			<!-- <div>当前位置：首页>积分商城><span>互联网</span></div> -->
		</div>
		<!-- 电商板块 -->
		<div class="Electricity">
			<div class="Electricity_cont">
				<!-- <h1>电商板块</h1> -->
				<div class="Electricity_cont_item">
					<div class="Electricity_cont_item1" id="sector">
						<div class="Electricity_cont_item1_text">
							多年技术行业经验沉淀
						</div>
						<div class="Electricity_cont_item2_text">
							在行业内具备了较强的产品竞争力和技术创新能力
						</div>
						<div >
							<img @dragstart.prevent @contextmenu.prevent class="img"
								src="../../assets/img/bootmall/hulianwang_1.png"
								alt=""
							/>
						</div>
					</div>
					<div class="Electricity_cont_item1">
						<div class="Electricity_cont_item1_text">
							积分兑换丰富多样，趣味挑战引力十足
						</div>
						<div class="Electricity_cont_item2_text">
							<!-- 通过积分活动引流拉新，引导新用户加入会员体系，培养用户忠诚度 -->
						</div>
						<div>
							<img @dragstart.prevent @contextmenu.prevent class="img"
								src="../../assets/img/bootmall/hulianwang_2.png"
								alt=""
							/>
						</div>
					</div>
					<div class="Electricity_cont_item1">
						<div class="Electricity_cont_item1_text">
							持续提升经营指标
						</div>
						<!-- <div class="Electricity_cont_item2_text"> -->
							<!-- 根据业务场景制定营销节点专属方案，轻松带来用户增长 -->
						<!-- </div> -->
						<div>
							<img @dragstart.prevent @contextmenu.prevent class="img"
								src="../../assets/img/bootmall/hulianwang_3.png"
								alt=""
							/>
						</div>
					</div>
					<div class="Electricity_cont_item1">
						<div class="Electricity_cont_item1_text">
							积分兑换丰富多样，趣味挑战引力十足
						</div>
						<!-- <div class="Electricity_cont_item2_text"> -->
							<!-- 通过游戏化场景延伸和物质奖励驱使，满足用户成就感，带动用户参与，提升平台活跃留存，并通过邀请好奖等任务设定，带来用户裂变增长，提升用户满意度 -->
						<!-- </div> -->
						<div>
							<img @dragstart.prevent @contextmenu.prevent class="img" style="height: 480px;width: 500px;"
								src="../../assets/img/bootmall/hulianwang_4.jpeg"
								alt=""
							/>
						</div>
					</div>
				</div>
			</div>
		</div>

		<footer2></footer2>

		<!-- end -->
	</div>
</template>

<script>
import footer2 from "../../components/footer.vue";
import banner2 from "../../components/banner.vue";
import nav2 from "../../components/nav.vue";
export default {
	name: "hulianwang",
	components: {
		footer2,
		nav2,
		banner2,
	},
	data() {
		return {
			h1: "互联网行业解决方案",
			P1: "搭建用户积分成长体系，配合营销玩法，实现全场景数据洞察，持续提升经营指标",
			Img1: require('@/assets/img/bootmall/hulianwang.png'),
		};
	},
	methods: {},
};
</script>
<style scoped>
@charset "utf-8";
/*=========================Reset_start==========================*/
body,
h1,
h2,
h3,
h4,
h5,
h6,
div,
p,
dl,
dt,
dd,
ol,
ul,
li,
form,
table,
th,
td,
a,
img,
span,
strong,
var,
em,
input,
textarea,
select,
option {
	margin: 0;
	padding: 0;
}
/*去内外边距*/
html,
body {
	font-family: "微软雅黑", "宋体", Arail, Tabhoma;
	text-align: left;
}
/*字体 文本居左*/
ul {
	list-style: none;
}
/*去ul li 小点*/
img {
	border: 0 none;
}
/*img IE6 兼容问题*/
input,
select,
textarea {
	outline: 0;
}
/*去除点击蓝框*/
textarea {
	resize: none;
	overflow: auto;
}
/*锁定多行文本框大小 与文字超出出现滚动条*/
table {
	border-collapse: collapse;
	border-spacing: 0;
}
/*表格边框合并 边框间距0*/
th,
strong,
var,
em {
	font-weight: normal;
	font-style: normal;
}
/*特殊文字粗细 样式显示正常*/
a {
	text-decoration: none;
}
/*a标签去下划线*/
.clearfix:after {
	clear: both;
	content: "";
	display: block;
	height: 0;
}
/*清浮动*/
a {
	color: #000000;
}
/*==========================Reset_End===========================*/

.youjiaopage_wrap {
	width: 100vw;
	margin: 0 auto;
	height: 1000px;
}
/* 面包屑 */
.Crumbs {
	padding-top: 60px;
	width: 1200px;
	height: 46px;
	margin: 0 auto;
}
.Crumbs {
	font-size: 12px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #999999;
	line-height: 17px;
}
.Crumbs div {
	line-height: 46px;
}
.Crumbs span {
	color: #000000;
}
/* 电商板块 */
.Electricity_cont {
	width: 1200px;
	margin: 0 auto;
	height: 2800px;
	text-align: center;
}

.Electricity_cont_item1 {
	width: 100%;
	min-width: 1200px;
	height: 700px;
	min-height: 700px;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	border-top: 1px solid hsla(0,0%,91%,.5);
}

.Electricity_cont_item1_text {
	margin: 53px 0 20px;
	width: 100%;
	height: 48px;
	font-size: 34px;
	font-weight: 500;
	color: #2d2d2d;
}

.Electricity_cont_item2_text {
	padding: 0 60px;
	margin-bottom: 60px;
	width: 1159px;
	min-height: 28px;
	font-size: 20px;
	font-weight: 400;
	color: #2d2d2d;
	line-height: 28px;
}



</style>

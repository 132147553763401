<template>
  <div>
    <button @click="toggleAllSelection">全选/全不选</button>
    <div v-for="item in items" :key="item.id">
      <input
        type="checkbox"
        :value="item"
        v-model="isSelected"
        @change="updateAllSelection"
      />
      {{ item.label }}
    </div>
    已选择{{isSelected.length}}件商品
  </div>
</template>
 
<script>
export default {
  data() {
    return {
      items: [
        { id: 1, label: '选项1' },
        { id: 2, label: '选项2' },
        { id: 3, label: '选项3' },
        // ...更多选项
      ],
      isAllSelected: false,
      isSelected: [] // 初始为空数组
    };
  },
  methods: {
    toggleAllSelection() {
      this.isAllSelected = !this.isAllSelected;
      if (this.isAllSelected) {
        this.isSelected = this.items.map(item => item); // 全选
      } else {
        this.isSelected = []; // 全不选
      }
    },
    updateAllSelection() {
      this.isAllSelected = this.isSelected.length === this.items.length;
    }
  }
};
</script>
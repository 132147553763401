<template>
  <!-- star -->
  <div class="kejipage_wrap">
    <!-- <banner2 :bannerH="h1" :bannerP="P1" :bannerImg="Img1"></banner2> -->
    <!-- 面包屑 -->
    <div class="Crumbs">
      <div><a href="/index">返回首页</a>><span>企业福采</span></div>
    </div>
    <!-- nav -->
    <div class="nav">
      <div class="wrap">
        <div class="logo">
          <a href="/index"><img @dragstart.prevent @contextmenu.prevent :src="logoimg" /></a>
        </div>
        <div class="phone">
          <span>企业服务热线：027-87208838</span>
        </div>
      </div>
    </div>
    <!-- 商品信息 -->
    <div class="det_par1 clearfix">
      <div class="det_par1_L left">
        <!-- 需要企业专属时添加类名jqzoom_qyzs，class="jqzoom jqzoom_qyzs" -->
        <div id="preview" class="spec-preview"><span class="jqzoom "><img @dragstart.prevent @contextmenu.prevent id="spuMainImg"
              :src="productInfo.image"
              alt="">
            <p></p>
          </span>
        </div>

        <!--缩图开始-->
        <div class="spec-scroll">
          <div class="items">
            <ul>
              <li><img @dragstart.prevent @contextmenu.prevent 
                :src="productInfo.image"
                  onmouseover="preview(this);">
              </li>
            </ul>
          </div>
        </div>
        <!--缩图结束-->
      </div>
      <div class="det_par1_R right">
        <div class="det_par_title">{{productInfo.storeName}}</div>
        <div class="det_par_price">
          <p>价格<span id="price">¥{{productInfo.price}}</span></p>
          <div class="mt30">
            <div class="m-tips b0">
              <i class="icon c9"></i>
              <p class="desc">
                本卡涉及卡密，为了安全，到货后需手动激活后才可正常兑换礼品。
              </p>
            </div>
          </div>
        </div>
        <div class="docs-example">
          <div class="mt30">
            <span>规格</span>
            <div class="det_par_price_list">
              <!-- 禁用添加类名disabled -->
              <label class="u-radio u-state-btn j-state-radio checked" canbuy="1">
                <img @dragstart.prevent @contextmenu.prevent src="/images/details/no_stock_grey.png" class="no-stock-grey" style="display: none;">
                <img @dragstart.prevent @contextmenu.prevent src="/images/details/no_stock.png" class="no-stock" style="display: none;">
                <input type="radio" value="3138" cardsampleid="6136"
                  skuimg="https://file.guanaitong.com/grus-gfs/product/mgr-gateway-common/by-days/2023-07-27/46b744b719cb31ac7f954931841b836b.jpg"
                  skuprice="100" skustock="1510" salestatus="1" name="myOption1">
                100型
              </label>
              <label class="u-radio u-state-btn j-state-radio" canbuy="1">
                <img @dragstart.prevent @contextmenu.prevent src="/images/details/no_stock_grey.png" class="no-stock-grey" style="display: none;">
                <img @dragstart.prevent @contextmenu.prevent src="/images/details/no_stock.png" class="no-stock" style="display:none">
                <input type="radio" value="3139" cardsampleid="6134"
                  skuimg="https://file.guanaitong.com/grus-gfs/product/mgr-gateway-common/by-days/2023-07-27/46b744b719cb31ac7f954931841b836b.jpg"
                  skuprice="200" skustock="2673" salestatus="1" name="myOption1">
                200型
              </label>
              <label class="u-radio u-state-btn j-state-radio" canbuy="1">
                <img @dragstart.prevent @contextmenu.prevent src="/images/details/no_stock_grey.png" class="no-stock-grey" style="display: none;">
                <img @dragstart.prevent @contextmenu.prevent src="/images/details/no_stock.png" class="no-stock" style="display:none">
                <input type="radio" value="3140" cardsampleid="6131"
                  skuimg="https://file.guanaitong.com/grus-gfs/product/mgr-gateway-common/by-days/2023-07-27/46b744b719cb31ac7f954931841b836b.jpg"
                  skuprice="500" skustock="2057" salestatus="1" name="myOption1">
                500型
              </label>
              <label class="u-radio u-state-btn j-state-radio" canbuy="1">
                <img @dragstart.prevent @contextmenu.prevent src="/images/details/no_stock_grey.png" class="no-stock-grey" style="display: none;">
                <img @dragstart.prevent @contextmenu.prevent src="/images/details/no_stock.png" class="no-stock" style="display:none">
                <input type="radio" value="3330" cardsampleid="6135"
                  skuimg="https://file.guanaitong.com/grus-gfs/product/mgr-gateway-common/by-days/2023-09-15/4d1da98429afd69753289e27644bd975.jpg"
                  skuprice="150" skustock="639" salestatus="1" name="myOption1">
                150型
              </label>
              <label class="u-radio u-state-btn j-state-radio" canbuy="1">
                <img @dragstart.prevent @contextmenu.prevent src="/images/details/no_stock_grey.png" class="no-stock-grey" style="display: none;">
                <img @dragstart.prevent @contextmenu.prevent src="/images/details/no_stock.png" class="no-stock" style="display:none">
                <input type="radio" value="3331" cardsampleid="6133"
                  skuimg="https://file.guanaitong.com/grus-gfs/product/mgr-gateway-common/by-days/2023-09-15/4d1da98429afd69753289e27644bd975.jpg"
                  skuprice="300" skustock="2639" salestatus="1" name="myOption1">
                300型
              </label>
              <label class="u-radio u-state-btn j-state-radio" canbuy="1">
                <img @dragstart.prevent @contextmenu.prevent src="/images/details/no_stock_grey.png" class="no-stock-grey" style="display: none;">
                <img @dragstart.prevent @contextmenu.prevent src="/images/details/no_stock.png" class="no-stock" style="display:none">
                <input type="radio" value="3332" cardsampleid="6132"
                  skuimg="https://file.guanaitong.com/grus-gfs/product/mgr-gateway-common/by-days/2023-09-15/4d1da98429afd69753289e27644bd975.jpg"
                  skuprice="400" skustock="793" salestatus="1" name="myOption1">
                400型
              </label>
              <label class="u-radio u-state-btn j-state-radio" canbuy="1">
                <img @dragstart.prevent @contextmenu.prevent src="/images/details/no_stock_grey.png" class="no-stock-grey" style="display: none;">
                <img @dragstart.prevent @contextmenu.prevent src="/images/details/no_stock.png" class="no-stock" style="display:none">
                <input type="radio" value="3333" cardsampleid="6130"
                  skuimg="https://file.guanaitong.com/grus-gfs/product/mgr-gateway-common/by-days/2023-09-15/4d1da98429afd69753289e27644bd975.jpg"
                  skuprice="600" skustock="970" salestatus="1" name="myOption1">
                600型
              </label>
              <label class="u-radio u-state-btn j-state-radio" canbuy="1">
                <img @dragstart.prevent @contextmenu.prevent src="/images/details/no_stock_grey.png" class="no-stock-grey" style="display: none;">
                <img @dragstart.prevent @contextmenu.prevent src="/images/details/no_stock.png" class="no-stock" style="display:none">
                <input type="radio" value="3334" cardsampleid="6129"
                  skuimg="https://file.guanaitong.com/grus-gfs/product/mgr-gateway-common/by-days/2023-09-15/4d1da98429afd69753289e27644bd975.jpg"
                  skuprice="800" skustock="939" salestatus="1" name="myOption1">
                800型
              </label>
              <label class="u-radio u-state-btn j-state-radio" canbuy="1">
                <img @dragstart.prevent @contextmenu.prevent src="/images/details/no_stock_grey.png" class="no-stock-grey" style="display: none;">
                <img @dragstart.prevent @contextmenu.prevent src="/images/details/no_stock.png" class="no-stock" style="display:none">
                <input type="radio" value="3335" cardsampleid="6128"
                  skuimg="https://file.guanaitong.com/grus-gfs/product/mgr-gateway-common/by-days/2023-09-15/4d1da98429afd69753289e27644bd975.jpg"
                  skuprice="1000" skustock="666" salestatus="1" name="myOption1">
                1000型
              </label>
              <label class="u-radio u-state-btn j-state-radio" canbuy="1">
                <img @dragstart.prevent @contextmenu.prevent src="/images/details/no_stock_grey.png" class="no-stock-grey" style="display: none;">
                <img @dragstart.prevent @contextmenu.prevent src="/images/details/no_stock.png" class="no-stock" style="display:none">
                <input type="radio" value="3336" cardsampleid="6137"
                  skuimg="https://file.guanaitong.com/grus-gfs/product/mgr-gateway-common/by-days/2023-09-15/4d1da98429afd69753289e27644bd975.jpg"
                  skuprice="50" skustock="869" salestatus="1" name="myOption1">
                50型
              </label>
            </div>
          </div>
        </div>
        <div class="det_par1_class">
          <p>类型<span>实物商品</span></p>
        </div>
        <div class="det_par1_jsq">
          <div class="det_par1_jsq_sl">数量</div>

          <div class="u-amount  j-amount">
            <i class="minus gatling-icon gatling-icon-minus disabled"></i>
            <input type="text" class="input-cont" value="1" data-min="1" data-max="1510">
            <!-- disabled="" 设置不可点击 -->
            <i class="add gatling-icon gatling-icon-add"></i>
          </div>
          <div class="det_par1_jsq_kc">库存<span id="stock">1510</span>件 </div>
          <p class="det_par1_p" style="display: none;"></p>
        </div>
        <div class="m-btn-group mt10">
          <a href="javascript:;" class="u-btn disabled lg" style="display: none">已下架</a>
          <!-- 不可点击状态添加类名disabled -->
          <a href="javascript:void(0);" class="u-btn u-btn-default-ghost u-btn-lg j-target btn-addshoppingcart addcar"
            data-agl-cvt="15" id="btnAdd" @click="addCart()">加入购物车</a>
          <!-- 去签署 认证弹层加类j-dialog4  j-dialog5 -->
          <a href="javascript:void(0)" class="u-btn u-btn-primary j-target j-dialog4 btn-buynow" data-agl-cvt="7"
            id="btnBuy"><i class=" det_icn"></i>立即购买</a>


          <form id="buynowSubmit" action="/order/buyNowConfirm" method="post">
            <input type="hidden" name="_csrf" value="d474251f-4124-41f4-be1a-d709db7c05eb">
            <input type="hidden" id="formSkuId" name="skuId" value="">
            <input type="hidden" id="formQuantity" name="quantity" value="">
          </form>

        </div>
      </div>
    </div>

    <div class="line lc1 mt16"></div>

    <!-- 商品详情 -->
    <div class="det_par1 clearfix">
      <div class="m-tabs m-tabs-primary j-tabs">
        <div class="m-tabs-nav-wrap">
          <div class="m-tabs-nav">
            <div class="m-tabs-tab active">商品介绍</div>
            <div class="m-tabs-tab">可兑换商品</div>
          </div>
        </div>
        <div class="m-tabs-content">

          <div class="m-tabs-tabPane active">
            <div class="det_par4_cen">
            </div>
          </div>
          <div class="m-tabs-tabPane">
            <div class="det_pro_list">
              <div class="det_pro_lis_class" style="text-align: left;margin-left: -20px;"><a categoryid="0"
                  cardsampleid="6136" categorytype="1" href="javascript:;" onclick="categorySearch(this)">全部商品</a><a
                  categoryid="32091" cardsampleid="6136" categorytype="1" href="javascript:;"
                  onclick="categorySearch(this)">50</a><a categoryid="32092" cardsampleid="6136" categorytype="1"
                  href="javascript:;" onclick="categorySearch(this)">100</a><a categoryid="32093" cardsampleid="6136"
                  categorytype="1" href="javascript:;" onclick="categorySearch(this)">150</a><a categoryid="32094"
                  cardsampleid="6136" categorytype="1" href="javascript:;" onclick="categorySearch(this)">200</a><a
                  categoryid="32095" cardsampleid="6136" categorytype="1" href="javascript:;"
                  onclick="categorySearch(this)">300</a><a categoryid="32096" cardsampleid="6136" categorytype="1"
                  href="javascript:;" onclick="categorySearch(this)">400</a><a categoryid="32097" cardsampleid="6136"
                  categorytype="1" href="javascript:;" onclick="categorySearch(this)">500</a><a categoryid="32098"
                  cardsampleid="6136" categorytype="1" href="javascript:;" onclick="categorySearch(this)">600</a><a
                  categoryid="32099" cardsampleid="6136" categorytype="1" href="javascript:;"
                  onclick="categorySearch(this)">800</a><a categoryid="32100" cardsampleid="6136" categorytype="1"
                  href="javascript:;" onclick="categorySearch(this)">1000</a></div>
              <div class="det_pro" id="det_pro" style="margin-left: -20px;">
                <ul>
                  <li style="cursor: pointer" onclick="toExchangeProductDetail('PC23051600033422')">
                    <div class="det_pro_img ind_par2_img "><img @dragstart.prevent @contextmenu.prevent
                        src="https://img1.guanaitong.com/grus-gfs/product/gmall-seller-mgr/by-days/2023-05-16/3f2eaaa74f25586a339ed9411af527dc.jpg">
                    </div>
                    <div class="det_pro_title">
                      <p>悦鲜活鲜牛奶 超瞬时杀菌鲜奶低温奶 组合装450ml/260ml</p>
                    </div>
                  </li>
                  <li style="cursor: pointer" onclick="toExchangeProductDetail('PC23040600015872')">
                    <div class="det_pro_img ind_par2_img "><img @dragstart.prevent @contextmenu.prevent
                        src="https://img1.guanaitong.com/grus-gfs/product/gmall-seller-mgr/by-days/2023-04-06/e09abd56654b16caf40fe2557ee6f246.jpg">
                    </div>
                    <div class="det_pro_title">
                      <p>贝蒂斯特级初榨橄榄油500ML*2礼盒</p>
                    </div>
                  </li>
                  <li style="cursor: pointer" onclick="toExchangeProductDetail('PC22081800002238')">
                    <div class="det_pro_img ind_par2_img "><img @dragstart.prevent @contextmenu.prevent
                        src="https://img1.guanaitong.com/grus-gfs/product/gmall-seller-mgr/by-days/2022-08-18/efeb4b2c1b7a8ea40bf36fb54875888d.jpg">
                    </div>
                    <div class="det_pro_title">
                      <p>凤凰 通勤车 男女通用款（赠：雷加尔 铝合金一体减震羽毛球拍套装）</p>
                    </div>
                  </li>
                </ul>
                <a class="det_mor" id="det_mor"></a>

              </div>
              
            </div>
          </div>
        </div>
      </div>
    </div>


    <!-- footer -->
    <footer2></footer2>

    <!-- end -->
  </div>
</template>

<script>
import footer2 from "../components/footer.vue";
import {getProductDetail} from '@/api/example';
// import banner2 from "../components/banner.vue";
// import Pages from "../components/recruitInfoPages.vue"
// import nav2 from "../components/nav.vue";
export default {
	name: "商品详情",
  props: ['id'],
	components: {
		footer2,
		// nav2,
		// banner2,
		// Pages
	},
	data() {
		return {
			h1: "企业福采",
			P1: "帮助品牌方打通销售通路，提高数字商品流通效率；为消费端构建权益场景，提升用户活跃！",
			Img1: this.$imgUrl+"/yuyua/img/keji/20230705162508.jpg",
			logoimg: this.$imgUrl+"/yuyua/img/YUYUAN.png", 
      productInfo:{},
			currentTab: 0,
            currentClass: 0,
			tabList: ['firstTab', 'secondTab', 'thirdTab'],
			goodList: [
				{
					id:1,
					title: "【实体卡】2023中秋福利套餐.月满中秋",
					price: 50.00,
					price2:1000.00,
					href:"",
					img:"https://file.guanaitong.com/grus-gfs/product/mgr-gateway-common/by-days/2023-07-27/46b744b719cb31ac7f954931841b836b.jpg?x-oss-process=image/resize,p_50"
				},
				{
					id:2,
					title: "【实体卡】2023中秋福利套餐·中秋月",
					price: 50.00,
					price2:1000.00,
					href:"",
					img:"https://file.guanaitong.com/grus-gfs/product/mgr-gateway-common/by-days/2023-07-27/46b744b719cb31ac7f954931841b836b.jpg?x-oss-process=image/resize,p_50"
				},
				{
					id:3,
					title: "【实体卡】2023中秋福利套餐·月野大作战",
					price: 50.00,
					price2:1000.00,
					href:"",
					img:"https://file.guanaitong.com/grus-gfs/product/mgr-gateway-common/by-days/2023-07-27/46b744b719cb31ac7f954931841b836b.jpg?x-oss-process=image/resize,p_50"
				},
				
				{
					id:4,
					title: "电子卡】2023中秋福利套餐.月满中秋",
					price: 50.00,
					price2:1000.00,
					href:"",
					img:"https://file.guanaitong.com/grus-gfs/product/mgr-gateway-common/by-days/2023-07-27/46b744b719cb31ac7f954931841b836b.jpg?x-oss-process=image/resize,p_50"
				},
				{
					id:5,
					title: "【电子卡】2023中秋福利套餐·中秋月",
					price: 50.00,
					price2:1000.00,
					href:"",
					img:"https://file.guanaitong.com/grus-gfs/product/mgr-gateway-common/by-days/2023-07-27/46b744b719cb31ac7f954931841b836b.jpg?x-oss-process=image/resize,p_50"
				},	
				{
					id:6,
					title: "【电子卡】2023中秋福利套餐·中秋月",
					price: 50.00,
					price2:1000.00,
					href:"",
					img:"https://file.guanaitong.com/grus-gfs/product/mgr-gateway-common/by-days/2023-07-27/46b744b719cb31ac7f954931841b836b.jpg?x-oss-process=image/resize,p_50"
				},
			],
      productslist: [
                {
                    "id": 273,
                    "pid": 272,
                    "path": "/0/272/",
                    "name": "中秋套餐",
                    "type": 1,
                    "url": "",
                    "extra": "http://yuyua.cn/mallimage/public/maintain/2021/12/25/37901b98fba74345ad0e8a575999ad09n9d3winw2c.jpg",
                    "status": true,
                    "sort": 0,
                    "list": [
            {
                "id": 7,
                "image": "http://yuyua.cn/mallimage/public/maintain/2021/12/25/37382cca048b4011bdbacd3cbbe0814daw1o5x0tco.jpg",
                "storeName": "车载小鹿首饰架摆件鹿角树形耳钉首饰展示架耳环架手链饰品收纳首饰挂件 白色",
                "price": "13.00",
                "otPrice": null,
                "sales": 0,
                "ficti": 46,
                "unitName": "个",
                "activity": "0,1,2,3",
                "activityH5": null,
                "cartNum": null,
                "stock": 1533,
                "flatPattern": ""
            },
            {
                "id": 4,
                "image": "http://yuyua.cn/mallimage/public/maintain/2021/12/25/3ed152917e3f4f7faa9414050b6cab49vuadulntb8.jpg",
                "storeName": "CAMILA&KORALI品牌包包女包斜挎单肩小包女式",
                "price": "116.00",
                "otPrice": null,
                "sales": 0,
                "ficti": 86,
                "unitName": "件",
                "activity": "0,1,2,3",
                "activityH5": null,
                "cartNum": null,
                "stock": 289,
                "flatPattern": ""
            }
            
        ]
                },
                {
                    "id": 275,
                    "pid": 272,
                    "path": "/0/272/",
                    "name": "国庆套餐",
                    "type": 1,
                    "url": "www",
                    "extra": "http://yuyua.cn/mallimage/public/maintain/2021/12/25/62414c551db44169a6429c0dd3d162833o6e82okff.jpg",
                    "status": true,
                    "sort": 0,
                    "list": []
                },
                {
                    "id": 276,
                    "pid": 272,
                    "path": "/0/272/",
                    "name": "高温福利",
                    "type": 1,
                    "url": "",
                    "extra": "http://yuyua.cn/mallimage/public/maintain/2021/12/25/4811fe50a33f48309337e67c3d36318exseuh6qys6.jpg",
                    "status": true,
                    "sort": 0,
                    "list": []
                },
                {
                    "id": 277,
                    "pid": 272,
                    "path": "/0/272/",
                    "name": "端午套餐",
                    "type": 1,
                    "url": "",
                    "extra": "http://yuyua.cn/mallimage/public/store/2021/12/25/476f2b8a50644f5f80124f831ac14aecct8ewiyvwf.png",
                    "status": true,
                    "sort": 0,
                    "list": []
                },
                {
                    "id": 279,
                    "pid": 272,
                    "path": "/0/272/",
                    "name": "清凉一夏",
                    "type": 1,
                    "url": "",
                    "extra": "http://yuyua.cn/mallimage/public/store/2021/12/25/f22830611d324e8fb10129e969f1ec661lcpu7346h.png",
                    "status": true,
                    "sort": 0,
                    "list": []
                }
            ]
    };
	},created() {
    this.getlist();
    // this.productslist = [];
	},   
	methods: {
    getlist: function() {
    // let that=this
    getProductDetail(this.id).then((res) =>{
        this.productInfo=res.data.productInfo
        })
  },
		toggleTab(current) {
            this.currentTab = current;
            this.currentClass = current;
        },
        addCart(){
          localStorage.setItem("cart", JSON.stringify(this.productslist));
        
          
        }
	},// 组件销毁前保存数据
  beforeDestroy() {
    localStorage.setItem("cart", JSON.stringify(this.productslist));
  }
};
</script>

<style scoped>
@charset "utf-8";
/*=========================Reset_start==========================*/
body,
h1,
h2,
h3,
h4,
h5,
h6,
div,
p,
dl,
dt,
dd,
ol,
ul,
li,
form,
table,
th,
td,
a,
img,
span,
strong,
var,
em,
input,
textarea,
select,
option {
	margin: 0;
	padding: 0;
}
/*去内外边距*/
html,
body {
	font-family: "微软雅黑", "宋体", Arail, Tabhoma;
	text-align: left;
}
/*字体 文本居左*/
ul {
	list-style: none;
}
/*去ul li 小点*/
img {
	border: 0 none;
}
/*img IE6 兼容问题*/
input,
select,
textarea {
	outline: 0;
}
/*去除点击蓝框*/
textarea {
	resize: none;
	overflow: auto;
}
/*锁定多行文本框大小 与文字超出出现滚动条*/
table {
	border-collapse: collapse;
	border-spacing: 0;
}
/*表格边框合并 边框间距0*/
th,
strong,
var,
em {
	font-weight: normal;
	font-style: normal;
}
/*特殊文字粗细 样式显示正常*/
a {
	text-decoration: none;
}
/*a标签去下划线*/
.clearfix:after {
	clear: both;
	content: "";
	display: block;
	height: 0;
}
/*清浮动*/
a {
	color: #000000;
}
/*==========================Reset_End===========================*/
/* par1 */
.det_par1{
  width: 1200px;
  margin: 25px auto;
}
.det_par1_L{
  width: 438px;
  float: left;
}


.jqzoom{float:left;border:none;position:relative;padding:0px;cursor:pointer;margin:0px;display:block;}
.zoomdiv{z-index:100;position:absolute;top:0px;left:0px;width:438px;height:438px;background:#ffffff;display:none;text-align:center;overflow:hidden;}
.jqZoomPup{z-index:10;visibility:hidden;position:absolute;top:0px;left:0px;width:20px;height:20px;border:1px solid #aaa;background:#ffffff ;
   opacity: 0.5;-moz-opacity: 0.5;-khtml-opacity: 0.5;filter: alpha(Opacity=50);}
.jqzoom_qyzs p{
  /* background: url(../images/details/det1.png) no-repeat center center; */
  width: 110px;
  height: 40px;
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 1;
}
.jqzoom  img{
  width: 438px;
  height: 438px;
}

.spec-preview{width:438px;height:438px;}
.spec-scroll{clear:both;margin-top:15px;width:438px;}

.spec-scroll .items{float:left;position:relative;width:438px;height:78px;overflow:hidden;}
.spec-scroll .items ul{position:absolute;width:999999px;height:78px;}
.spec-scroll .items ul li{float:left;width:78px; margin-right: 13px; }
.spec-scroll .items ul li img{width:72px;height:72px;border: 3px solid #fff;}
.spec-scroll .items ul li img:hover{border:3px solid #ffb74d;}


.det_par1_R{
  width: 702px;
}
.det_par_title{
  font-size: 20px;
  color: #333333;
  line-height: 30px;
  font-weight: bold;
}
.det_par_price{
  width: 705px;
  height: 155px;
  background: #f7f7f7;
  padding: 20px;
  margin-top: 40px;
}
.det_par_price p{
  font-size: 14px;
  color: #333333;
}
.det_par_price p span{
   font-size: 28px;
   color: #ee8028;
   margin-left: 15px;
 }

.exchange_product_price{
  width: 705px;
  height: 80px;
  background: #f7f7f7;
  padding: 20px;
  margin-top: 40px;
}
.exchange_product_price p{
  font-size: 14px;
  color: #333333;
}
.exchange_product_price p span{
  font-size: 28px;
  color: #ee8028;
  margin-left: 22px;
}

.exchange_product_info{
  margin: 20px 0 10px 20px;
}

.exchange_product_info span{
  margin: -4.5px 0 0 23px;
}

.exchange_product_sku{
  margin: 0 0 10px 20px;
}

.exchange_product_label{
  width: 630px;
  display: inline-block;
  *display: inline;
  zoom: 1;
}

.exchange_product_label .u-radio>input{
  cursor: auto;
}

.exchange_product_label .u-checkbox>input{
  cursor: auto;
}


.m-tips{ padding: 5px 10px; background-color: #f8f0e6; border: 1px #F4E0A4 solid; color: #ee8028; font-size: 14px; height: 50px; line-height: 40px; position: relative;}
.det_par_price .m-tips p{color: #ee8028;}
.m-tips .icon{
  float: left;
  margin-right: 6px;
  /* background: url(../images/details/det2.png) no-repeat center center; */
  width: 18px;
  height: 18px;
  top: 9px;
}
.m-tips .desc{ overflow: hidden; }
.m-tips.b0{border-color: #f8f0e6;}
.u-checkbox:before, .u-radio:before{
  /* background: url(../images/details/icon-check.png) no-repeat */
}
.docs-example span{
  font-size: 14px;
  color: #333;
  display: inline-block;
  *display: inline;
  zoom: 1;
  height: 30px;
  line-height: 30px;
  vertical-align: top;
  margin-right: 10px;
}
.u-state-btn.checked{
  border: 1px solid #ffb74d
}
.u-state-btn{
  vertical-align: middle;
  line-height: 30px;
  padding:0 30px;
}
.u-checkbox>input, .u-radio>input{
  cursor: pointer;
}
.det_par1_class{
  margin-top: 25px;
}
.det_par1_class p{
  font-size: 14px;
  color: #333333;
}
.det_par1_class p span{
  font-size: 14px;
  color: #000000;
  margin-left: 15px;
}
.det_par1_jsq_sl{
  font-size: 14px;
  color: #333;
  float: left;
  height: 26px;
  line-height: 26px;
  margin-right: 15px;
}
.j-amount{
  float: left;
  margin-right: 10px;
}
.u-amount {
    overflow: hidden;
    text-align: center;
    display: inline-block;
    position: relative;
}
.u-amount .disabled {
    color: #ccc;
    background: #f7f7f7;
}
.u-amount .add:before, .u-amount .minus:before {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 14px;
    height: 14px;
    line-height: 1;
    display: block;
    font-size: 14px;
    margin: -7px 0 0 -7px;
}

.gatling-icon-add:before {
    content: "+";
}
.gatling-icon-minus:before {
    content: "-";
}
.u-amount .input-cont {
    width: 48px;
    height: 26px;
    line-height: 24px;
    float: left;
    border: none;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    text-align: center;
    font-size: 12px;
    color: #666;
    padding: 0 10px;
}
.u-amount .add, .u-amount .minus {
    width: 26px;
    height: 26px;
    float: left;
    border: 1px solid #ddd;
    position: relative;
    background: #fff;
    color: #999;
    cursor: pointer;
}
.gatling-icon {
    font-family: "gatling-icon" !important;
    font-size: 16px;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.det_par1_jsq_kc{
  font-size: 12px;
  color: #666666;
  height: 26px;
  line-height: 26px;

}
.det_par1_jsq{
  margin-top: 25px;
  height: 50px;
}
.det_par1_p{
  font-size: 12px;
  color: #de0303;
  margin-top: 5px;
  padding-left: 43px;
}
.u-btn{
  border-radius: 0px;
  margin-right: 30px;
  height: 48px;
  line-height: 48px;
  font-size: 18px;
}
.u-btn.u-btn-default-ghost{
  color: #ee8028;
  background: #fbf7f2;
  border: 1px solid #ee8028
}
.u-btn.u-btn-default-ghost:hover{
  background: #fff;
  border: 1px solid #ee8028
}
.u-btn.u-btn-default-ghost.disabled, .u-btn.u-btn-default-ghost.disabled:hover {
  background-color: #ededed;
  border-color: #ededed;
  color: #ccc;
  text-decoration: none;
  cursor: default;
}
.u-btn.u-btn-primary{
  background: #ee8028;
  border: 1px solid #ee8028
}
.u-btn.u-btn-primary:hover{
  background: #f46f04;
  border: 1px solid #f46f04
}

.u-btn.u-btn-primary.disabled, .u-btn.u-btn-primary.disabled:hover {
  background-color: #ededed;
  border-color: #ededed;
  color: #ccc;
  text-decoration: none;
  cursor: default;
}

.det_icn{
  display: inline-block;
  display: inline;
  zoom: 1;
  height:23px;
  width:28px;
  /* background: url(../images/details/det_icn.png) no-repeat center center; */
  vertical-align: text-bottom;
  margin-right: 5px;
}
/* par1 */
.kejipage_wrap {
	width: 100vw;
	margin: 0 auto;
	height: 1000px;
}
/* 面包屑 */
.Crumbs {
	width: 1200px;
	height: 46px;
	margin: 0 auto;
}
.Crumbs {
	font-size: 12px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #999999;
	line-height: 17px;
}
.Crumbs div {
	line-height: 46px;
}
.Crumbs div a{

	color: #999;
	cursor: pointer;
}
.Crumbs span {
	color: #000000;
}
.nav{
	width: 100vw;
	box-shadow: 0px 0px 3px rgba(155, 155, 155, 0.1);

}
.wrap {
	width: 1200px;
	height: 101px;
	line-height: 101px;
	margin: 0 auto;
}

.wrap > .logo a > img {
	/* position: absolute; */
	/* left: 190px; */
	float: left;
	margin-top: 23px;
	width: 181px;
	/* height: 54px; */
}

.wrap .phone{
	/* width: 400px; */
	height: 101px;
	text-align: right;
}
/* news */
.news {
	width: 100vw;
	height: 801px;
	background: #f8f8f8;
}
.news_cont {
	width: 1200px;
	margin: 0 auto;
	display: flex;
	justify-content: space-between;
}
.news_cont_left {
	margin-top: 89px;
}
.news_cont_left_img img {
	width: 588px;
	height: 353px;
	margin-top: 41px;
}
.news_cont_left_text {
	padding-top: 27px;
	padding-left: 40px;
	width: 548px;
	height: 150px;
	background-color: #ffffff;
}
.news_cont_left_text h1 {
	font-size: 18px;
	font-weight: 500;
	color: #23337f;
}
.news_cont_left_text p {
	font-size: 14px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #8f8f8f;
	margin-top: 10px;
}
.news_cont_right {
	width: 588px;
	margin-top: 60px;
}
.news_cont_right_ul ul {
	font-size: 16px;
	font-weight: 400;
	color: #b3b3b3;
	line-height: 22px;
	float: right;
	display: flex;
}
.news_cont_right_ul ul a {
	font-size: 16px;
	font-weight: 400;
	color: #b3b3b3;
	line-height: 22px;
	float: right;
	display: flex;
}
.news_cont_right_ul ul li:hover {
	color: #23337f;
}
.news_cont_right_ul ul span {
	margin: 0 23px 0 24px;
}
.news_cont_right_text {
	width: 588px;
	height: 535px;
	background: #ffffff;
	margin-top: 68px;
}
.news_cont_right_text_item {
	padding: 36px;
}
.news_cont_right_text_item h1 {
	font-size: 18px;
	font-family: PingFangSC-Medium, PingFang SC;
	font-weight: 500;
	color: #444444;
	line-height: 25px;
}
.news_cont_right_text_item p {
	margin-top: 10px;
	font-size: 14px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #8f8f8f;
	line-height: 20px;
}
.news_cont_right_text_hr {
	/* float: right; */
	width: 508px;

	border-bottom: 1px solid #d7d7d7;
}
.news_cont_right_text_hr:nth-of-type(3) {
	/* float: right; */
	width: 508px;

	border-bottom: none;
}
.news_cont_right_text_item2 {
	padding: 24px 40px;
}
.news_cont_right_text_item2 h1 {
	font-size: 18px;
	font-family: PingFangSC-Medium, PingFang SC;
	font-weight: 500;
	color: #444444;
	line-height: 25px;
}
.news_cont_right_text_item2 p {
	margin-top: 10px;
	font-size: 14px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #8f8f8f;
	line-height: 20px;
}

/* 商品列表 */
.page_tab_content{width:100000px;}

.m-tabs-nav{float:left}
.m-tabs-nav div{
  display: inline-block;
  *display: inline;
  zoom: 1;
}

.commod_par1 .m-tabs-nav-wrap .prev_tab {
  position: absolute;
  top: 0;
  left: 0;
  width: 35px;
  height: 35px;
  box-shadow: 0 0 3px #c6c6c7;
  background: #fff;
  font-family: '宋体';
  font-size: 25px;
  text-align: center;
  line-height: 35px;
  color: #999;
  display: none
}
.commod_par1 .m-tabs-nav-wrap .next_tab {
  position: absolute;
  top: 0;
  right: 0;
  width: 35px;
  height: 35px;
  box-shadow: 0 0 3px #c6c6c7;
  background: #fff;
  font-family: '宋体';
  font-size: 25px;
  text-align: center;
  line-height: 35px;
  color: #999;
  display: none
}
.commod_bg{
  width: 100%;
  min-width: 1200px;
  background: #f7f7f7;
  padding-top: 25px;
  padding-bottom: 25px;

}
/* commod_par1 */
.commod_par1{
  width: 1200px;
  margin: 0 auto;
  background:#fff;
  box-shadow: 0px 0px 3px rgba(155, 155, 155, 0.1);
  padding: 20px;
}
.commod_par1_tab>.m-tabs-nav-wrap>.page_tab_content>.m-tabs-nav>.m-tabs-tab.active{
  border: 0 none;
  background: none;
  margin: 0;
  padding:0 20px;
  border-bottom: 3px solid #ffb74d;
  color: #ffb74d;
}
.commod_par1_tab>.m-tabs-nav-wrap>.page_tab_content>.m-tabs-nav>.m-tabs-tab{
  border: 0 none;
  background: none;
  padding: 0;
  color: #666;
  font-size: 14px;
  padding:0 20px;
  height: 35px;
  line-height: 35px;
  display: table-cell;
  cursor: pointer;
}
.commod_par1_tab>.m-tabs-nav-wrap{
  /* height: 35px;
  line-height: 35px; */
  overflow: hidden;
  border-bottom: 0 none;
  position: relative;
}
.commod_par1_tab>.m-tabs-content{
  border: 0 none;
  border-top: 1px solid #f7f7f7;
  padding: 0;
  padding-top: 11px;
}
.commod_par1_tab .m-tabs-tabPane{
  font-size: 0px;
}
.commod_par1_tab .m-tabs-tabPane a{
  display: inline-block;
  *display: inline;
  zoom: 1;
  font-size: 14px;
  color: #333333;
  margin-right:45px;
  height: 42px;
  line-height: 42px;

}
.commod_par1_tab .m-tabs-tabPane a:hover,.commod_par1_tab .m-tabs-tabPane .active{
  color: #ffb74d;
}
/* commod_par1 */
/* commod_par2 */
.commod_par2{
  width: 1200px;
  margin: 0 auto;
  background:#fff;
  box-shadow: 0px 0px 3px rgba(155, 155, 155, 0.1);
  padding: 20px;
  margin-top: 25px;
}
.commod_par2_img{
  width: 276px;
  height: 276px;
  overflow: hidden;
}
.commod_par2_img img{
  width: 100%;
}
.commod_par2_title{
  font-size: 14px;
  color: #000000;
  padding: 0 25px;
  margin: 0 auto;
  text-align: center;
  height: 90px;
  padding-top: 20px;
  background: #fff;
  transition:all 0.5s;
  -moz-transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;

}
.commod_par2_title p{
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  transition:all 0.5s;
  -moz-transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
}
.commod_par2_title span{
  color: #ee8028;
  display: block;
  margin-top: 5px;
  font-weight: bold;
  font-size: 18px;
  margin-left: 6px;
}
.commod_par2_title span sub{
  font-size: 12px;
  font-weight: normal;
  margin-right: 2px;
}
.commod_par2 ul{
  font-size: 0px;
}
.commod_par2 ul li{
  display: inline-block;
  *display: inline;
  zoom: 1;
  width: 276px;
  margin:0 9px;
  margin-bottom: 20px;
  transition:all 0.5s;
  -moz-transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;

}
.commod_par2 ul li:hover {
  box-shadow:0px 0px 12px rgba(155, 155, 155, 0.32)
}
.commod_par2 ul li:hover .commod_par2_title{
  background: #f7f7f7;

}
.commod_par2 ul li:hover .commod_par2_title p{
  color: #FFB74D;

}

.g-ctrn {
    min-height: 450px;
    padding-bottom: 50px;
    display: inline-block;
    width: 100%
}

.g-ctrn .m-error-page,.g-ctrn .m-result {
    padding-top: 100px
}
/* 图片加载 */
.lazy_Bg{
  width: 100%;
  height: 100%;
  /* background: url(../images/public/load.jpg) no-repeat center center; */
}
/* 图片加载 */
/* commod_par2 */
/* 商品列表 */
/* 面包屑 */
.set_bread{
  width: 1200px;
  margin: 0 auto;
  margin-top: 60px;
}
.m-bread{ display: block; font-size: 12px; color: #333333; }
.m-bread .gatling-icon{
  font-size: 12px;
  color: #333;
}
.m-bread a{ color: #333; }
.m-bread a:hover{
  color: #FFB74D;
}
.m-bread .cur{
  color: #333;
  width: 220px;
  display: inline-block;
  *display: inline;
  zoom: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  vertical-align: middle;
}
.m-bread .name{
  margin-right: 0px;;
}

/* 面包屑 */
/* par1 */


.jqzoom{float:left;border:none;position:relative;padding:0px;cursor:pointer;margin:0px;display:block;}
.zoomdiv{z-index:100;position:absolute;top:0px;left:0px;width:438px;height:438px;background:#ffffff;display:none;text-align:center;overflow:hidden;}
.jqZoomPup{z-index:10;visibility:hidden;position:absolute;top:0px;left:0px;width:20px;height:20px;border:1px solid #aaa;background:#ffffff /*url(../images/zoom.png) 50% center no-repeat*/;opacity: 0.5;-moz-opacity: 0.5;-khtml-opacity: 0.5;filter: alpha(Opacity=50);}
.jqzoom_qyzs p{
  /* background: url(../images/details/det1.png) no-repeat center center; */
  width: 110px;
  height: 40px;
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 1;
}
.jqzoom  img{
  width: 438px;
  height: 438px;
}

.spec-preview{width:438px;height:438px;}
.spec-scroll{clear:both;margin-top:15px;width:438px;}

.spec-scroll .items{float:left;position:relative;width:438px;height:78px;overflow:hidden;}
.spec-scroll .items ul{position:absolute;width:999999px;height:78px;}
.spec-scroll .items ul li{float:left;width:78px; margin-right: 13px; }
.spec-scroll .items ul li img{width:72px;height:72px;border: 3px solid #fff;}
.spec-scroll .items ul li img:hover{border:3px solid #ffb74d;}
/* .u-checkbox>input, .u-radio>input {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    opacity: 0;
    color: #666;
    font: inherit;
}

.det_par_price_list {
    width: 660px;
    display: inline-block;
    zoom: 1;
} */

.det_par1_R{
  width: 702px;
  float: right;
}
.det_par_title{
  font-size: 20px;
  color: #333333;
  line-height: 30px;
  font-weight: bold;
}
.det_par_price{
  width: 705px;
  height: 155px;
  background: #f7f7f7;
  padding: 20px;
  margin-top: 40px;
}
.det_par_price p{
  font-size: 14px;
  color: #333333;
}
.det_par_price p span{
   font-size: 28px;
   color: #ee8028;
   margin-left: 15px;
 }

.exchange_product_price{
  width: 705px;
  height: 80px;
  background: #f7f7f7;
  padding: 20px;
  margin-top: 40px;
}
.exchange_product_price p{
  font-size: 14px;
  color: #333333;
}
.exchange_product_price p span{
  font-size: 28px;
  color: #ee8028;
  margin-left: 22px;
}

.exchange_product_info{
  margin: 20px 0 10px 20px;
}

.exchange_product_info span{
  margin: -4.5px 0 0 23px;
}

.exchange_product_sku{
  margin: 0 0 10px 20px;
}

.exchange_product_label{
  width: 630px;
  display: inline-block;
  *display: inline;
  zoom: 1;
}

.exchange_product_label .u-radio>input{
  cursor: auto;
}

.exchange_product_label .u-checkbox>input{
  cursor: auto;
}


.m-tips{ padding: 5px 10px; background-color: #f8f0e6; border: 1px #F4E0A4 solid; color: #ee8028; font-size: 14px; height: 50px; line-height: 40px; position: relative;}
.det_par_price .m-tips p{color: #ee8028;}
.m-tips .icon{
  float: left;
  margin-right: 6px;
  /* background: url(../images/details/det2.png) no-repeat center center; */
  width: 18px;
  height: 18px;
  top: 9px;
}
.m-tips .desc{ overflow: hidden; }
.m-tips.b0{border-color: #f8f0e6;}
.u-checkbox:before, .u-radio:before{
  /* background: url(../images/details/icon-check.png) no-repeat */
}
.docs-example span{
  font-size: 14px;
  color: #333;
  display: inline-block;
  *display: inline;
  zoom: 1;
  height: 30px;
  line-height: 30px;
  vertical-align: top;
  margin-right: 10px;
}
.u-state-btn.checked{
  border: 1px solid #ffb74d
}
.u-state-btn{
  vertical-align: middle;
  line-height: 30px;
  padding:0 30px;
}
.u-checkbox>input, .u-radio>input{
  cursor: pointer;
}
.det_par1_class{
  margin-top: 25px;
}
.det_par1_class p{
  font-size: 14px;
  color: #333333;
}
.det_par1_class p span{
  font-size: 14px;
  color: #000000;
  margin-left: 15px;
}
.det_par1_jsq_sl{
  font-size: 14px;
  color: #333;
  float: left;
  height: 26px;
  line-height: 26px;
  margin-right: 15px;
}
.j-amount{
  float: left;
  margin-right: 10px;
}
.det_par1_jsq_kc{
  font-size: 12px;
  color: #666666;
  height: 26px;
  line-height: 26px;

}
.det_par1_jsq{
  margin-top: 25px;
  height: 50px;
}
.det_par1_p{
  font-size: 12px;
  color: #de0303;
  margin-top: 5px;
  padding-left: 43px;
}
.u-btn{
  border-radius: 0px;
  margin-right: 30px;
  height: 48px;
  line-height: 48px;
  font-size: 18px;
}
.u-btn.u-btn-default-ghost{
  color: #ee8028;
  background: #fbf7f2;
  border: 1px solid #ee8028
}
.u-btn.u-btn-default-ghost:hover{
  background: #fff;
  border: 1px solid #ee8028
}
.u-btn.u-btn-default-ghost.disabled, .u-btn.u-btn-default-ghost.disabled:hover {
  background-color: #ededed;
  border-color: #ededed;
  color: #ccc;
  text-decoration: none;
  cursor: default;
}
.u-btn.u-btn-primary{
  background: #ee8028;
  border: 1px solid #ee8028
}
.u-btn.u-btn-primary:hover{
  background: #f46f04;
  border: 1px solid #f46f04
}

.u-btn.u-btn-primary.disabled, .u-btn.u-btn-primary.disabled:hover {
  background-color: #ededed;
  border-color: #ededed;
  color: #ccc;
  text-decoration: none;
  cursor: default;
}

.det_icn{
  display: inline-block;
  *display: inline;
  zoom: 1;
  height:23px;
  width:28px;
  /* background: url(../images/details/det_icn.png) no-repeat center center; */
  vertical-align: text-bottom;
  margin-right: 5px;
}
/* par1 */
/* par2 */
.line{
  width: 1200px;
  margin: 0 auto;
  margin-top: 90px;
}
.det_title{
  font-size: 20px;
  color: #333333;
  text-align: center;
  font-weight: bold;
  margin-top: 30px;
}
.det_title p{
  font-size: 14px;
  color: #999999;
  font-weight: normal;
}
.det_pro_list{
  width: 1200px;
  margin: 0 auto;
  margin-top: 25px;
}
.det_pro_lis_class{
  font-size: 0px;
  text-align: center;
}
.det_pro_lis_class a{
  font-size: 14px;
  color: #333333;
  display: inline-block;
  *display: inline;
  zoom: 1;
  margin: 0 23px;
}
.det_pro_lis_class a:hover,.det_pro_lis_class .active{
  color: #ffb74d;
}
.det_pro_img{
  width: 285px;
  height: 285px;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  align-items: center;
  -webkit-align-items:center;
  justify-content: center;
  -webkit-justify-content: center;
}
.det_pro_img img{
  width: 100%;
}
.det_pro_title{
  font-size: 14px;
  color: #000000;
  padding: 0 25px;
  margin: 0 auto;
  text-align: center;
  height: 90px;
  padding-top: 30px;
  background: #fff;
  transition:all 0.5s;
  -moz-transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;

}
.det_pro_title p{
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  transition:all 0.5s;
  -moz-transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
}
.det_pro_title span{
  color: #ee8028;
  display: block;
  margin-top: 5px;

}
.det_pro{
  margin-top: 60px;
  /*height: 1155px;*/
  overflow: hidden;
  transition:all 0.5s;
  -moz-transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  position: relative;
}
.det_pro ul{
  font-size: 0px;
  display: flex;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  align-items: center;
  -webkit-align-items:center;
  width: 1240px;
  justify-content: flex-start;
  -webkit-justify-content: flex-start;
}
.det_pro ul li{
  width: 285px;
  margin-bottom: 20px;
  margin-right: 20px;
}
.det_pro ul li:hover {
  box-shadow:0px 0px 12px rgba(155, 155, 155, 0.32)
}
.det_pro ul li:hover .det_pro_title{
  background: #f7f7f7;

}
.det_pro ul li:hover .det_pro_title p{
  color: #FFB74D;

}
.det_mor{
  display: none;
  width: 1200px;
  height: 301px;
  /* background: url(../images/details/bet_3.png) repeat-x center center; */
  font-size: 14px;
  color: #999999;
  margin-top: -280px;
  padding-top: 275px;
  text-align: center;
  cursor: pointer;
  position: absolute;
  left: 0px;
  bottom: 0px;
  z-index: 1;
}
.det_pro_mor{
  height: 1155px;
}
.det_mor_cat{
  height: 30px;
  /* background: url(../images/details/bet_3.png) repeat-x center bottom; */
  line-height: 30px;
  padding: 0px;
  margin-top: 0px;

}
/* par2 */
/* par3 */
.det_par3_cen{
  width: 1200px;
  margin: 0 auto;
  margin-top: 60px;
}

/* par3 */

/* par4 */
.det_par4_cen{
  width: 1200px;
  margin: 0 auto;
}
.product-detail-module-title {
  margin-bottom: 38px;
  color: #333;
  text-align: center;
  font-weight: normal;
}
.exchange-ways-container .sub-title, .exchange-notices .sub-title {
  font-size: 18px;
  color: #333;
  font-weight: normal;
  border-bottom: 1px solid #E8E8E8;
  padding-bottom: 4px;
  margin-bottom: 12px;
}
.exchange-notice-list li {
  color: #ec6618;
  height: 40px;
  line-height: 40px;
}


.exchange-notice-list li {
  list-style: disc inside;
}
.exchange-process-info{ padding:0 !important; margin:0 !important;border: 0px solid #ccc !important;}

.kbj_main{ width:1090px; display:block; margin:0 auto; padding:0;}
.product_show{ margin-bottom:26px;}
.kbj_t1{ width:100%; height:140px; display:block; background:#f4f4f4; padding-top:58px;}
.kbj_t1 h1{ text-align:center; font-size:40px; height:40px; line-height:40px; margin:0 0 26px 0px; padding:0; font-weight:normal; }
.kbj_t1 h1 em{background:url(//www.guanaitong.com/nj/content/images/zq-pic/i_bg.png) center no-repeat; font-style:normal; height:20px; width:20px; padding:10px 15px}
.cp-pic01{ width:100%; height:626px; display:block; padding:0; margin:0;}
.cp-pic02{ width:100%; height:402px; display:block; padding:0; margin:0; background:#f4f4f4;}
.cp-pic02 img{ float:left;  height:402px;}
.cp-info{ height:402px;   display: table-cell;vertical-align:middle}
.cp-info h2{height:43px;   line-height:43px; padding-bottom:4px;border-bottom:2px solid #df5417; font-weight:normal; margin:0px 0px 15px 0px; min-width:340px;}
.cp-info h2 strong{ height:39px; font-size:36px; line-height:39px;  border-bottom:4px solid #df5417; margin-right:10px;}
.cp-info h2 em{ height:43px; font-size:22px; line-height:43px;  font-style:normal; }
.cp-info p{ font-size:24px; line-height:34px;color:#454545; padding:0 ;margin:0;}
.cp-info span{ font-size:20px; color:#454545; margin-top:5px; display:block;}
.look-more{ width:100%; height:66px; background:#f4f4f4; }
.look-more a{ margin:0 auto; display:block; font-size:16px; text-align:center; text-decoration:none;}
.look-more a:hover{ color:#c36146;}
.kbj_t1 p{ font-size:24px; display:block; text-align:center; color:#454545; padding:0; margin:0;}
.cs{ color:#df5417;}
.theme_img{float:left; margin-top:90px;}
.product-detail-module-title{padding-top: 70px;padding-bottom: 38px; margin-bottom:0px !important; border:1px solid #E8E8E8;  border-bottom:0px none;}
.content{font-size:28px; color:#000;}
.exchange-ways-container{padding:0 40px; border-left:1px solid #E8E8E8 ;border-right:1px solid #E8E8E8;border-bottom:1px solid #E8E8E8; padding-bottom:42px;}
.sub-title{font-size:18px; color:#000000; height:40px;}
.exchange-ways-container .theme_ul{float:right; width:420px; margin-top:90px;}
.exchange-ways-container .theme_ul li{ padding-bottom:40px;}
.exchange-ways-container .theme_ul li.funv_ewm{
  /* background: url(../images/ewm.jpg) no-repeat right center; */
  padding-right: 100px;
}
.exchange-ways-container .theme_ul li img{ float:left;}
.exchange{ display: table-cell;vertical-align:middle; height:60px; padding-left:20px; }
.exchange p{ margin-bottom:5px;font-size:18px; color:#000000;}
.exchange span{font-size:12px; color:#000; }
.exchange-notices{ margin-top:100px;}
.exchange-notice-list li{color:#ec6618; height:40px; line-height:40px;}
.exchange-notice-list span{color:#000000; font-size:16px;}
.foot_logo{ margin-bottom:150px; margin-top:100px;}

.P_ys{ position:relative;}
.P_ys p{ font-size:44px; color:#fff; font-weight:bold; line-height:57px; position:absolute; left:60px; top:190px; }
.P_ys span{ font-size:24px; color:#fff; position:absolute; left:60px; top:316px;}
.p_why{ padding:35px; background:#585ab0;}
.P_img_1{ position: absolute;left:60px; top:60px;}

.p_why h1{ text-align:center; font-size:40px; height:40px; line-height:40px; margin:0 0 26px 0px; padding:0; font-weight:normal; color:#fff; padding-top:20px; }
.p_why em{background:url(//www.guanaitong.com/nj/content/images/P1.png) center no-repeat; font-style:normal; height:20px; width:20px; padding:10px 15px }
.p_why p{font-size:24px; display:block; text-align:center; color:#fff; padding:0; margin:0; line-height:40px;}
.p_why ul{ padding:28px 0; width:1020px; background:#ffffff; margin-top:55px;font-size:0px; text-align:center;}
.p_why ul li{ width:254px; display:inline-block; *display:inline; zoom:1; text-align:center; border-right:1px dashed #eeeeee; }
.p_why ul .none_bor{ border:0 none;}
.p_why ul li img{ display:block; margin:0 auto;}
.p_why ul li p{ font-weight:bold; font-size:20px; color:#424242; margin-top:15px;}
.p_why ul li span{ font-size:14px; line-height:24px; display:block; color:#424242; margin-top:10px;}

.p_why_sr{ background:#f18208}
.P_ding{ margin-top:1px;}
.p_why_xn{ background: url(//www.guanaitong.com/nj/content/images/xn-pic/xn2.png)}
.kbj_t1_xn{ color: #df5417;}
.P_ys_sr p{top: 100px;}
.P_ys_sr span{top:290px;}
.P_ys_dw p{top:150px;}
.P_ys_dw span{top:280px;}
.p_why_dw{background: #18775b}
.cp-info_dw p{font-size: 16px}
.P_ys_xr p{
  top: 90px;
  font-size: 40px
}
.P_ys_xr span{
  top: 280px
}
.p_why_xr{
  background: #5081bb
}
.cp-info_dw  .lingsasd{
  line-height: 25px;
}
.kbs_img{
  margin: 20px 0;
  display: block;
}
/* par4 */

/* 商品下架 */

.lower_cen p{
  font-size: 24px;
  color: #333333;
  text-align: center;
}
.agr_close{
  display: block;
  margin: 0 auto;
  width: 150px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  font-size: 18px;
  color: #fff;
  background: #ee8028;
  border-radius: 25px;
  margin-top: 30px;
}
.agr_close:hover{
  background: #f46f04;
  text-decoration: none;
}
.m-error-page{
  font-size: 16px;
  color: #999;
  text-align: center;
}
.m-error-page i{
  margin: 0 auto 10px;
  display: block;
}

/* 商品飞入购物车 */
#msg{position:fixed; top:300px; right:35px; z-index:10000; width:1px; height:52px; line-height:52px; font-size:20px; text-align:center; color:#fff; background:#360; display:none}
.u-flyer{display: block;width: 50px;height: 50px;border-radius: 50px;position: fixed;z-index: 9999;}

/* 新加商品分类实体虚拟 */
.commod_par1_tab .m-tabs-tabPane span{
  display: block;
  font-size: 14px;
  color: #333333;
  float: left;
  height: 42px;
  line-height: 42px;
}
.m-tabs-tabPane_div{
  padding-left: 60px;
}
.m-tabs-tabPane_line{
  border-bottom: 1px solid #f7f7f7;
}

/* 2019.12.12定制icn添加 */
.pro_dz{
  margin-top: 13px;
}
.commod_par2_title{
  text-align: start;
}
.commod_par2_title .pro_dz{
  float: right;
  margin-top: 7px;
}
.pro_dz_none{
  display: none;
}






.det_par_price_list {
    width: 660px;
    display: inline-block;
    zoom: 1;
}
.u-state-btn {
    vertical-align: middle;
    /* line-height: 30px; */
    padding: 0 30px;list-style-type: none;
}
.mt10 {
    margin-top: 10px;
}
.mt30 {
    margin-top: 30px;
}
.u-radio >input{-webkit-user-select:text;-ms-user-select:text;user-select:text;-webkit-appearance:none;font-size:1em;line-height:1.5em;list-style:none;}
.u-radio >input{outline:none;border:none;background:none;}
.u-state-btn {
    display: inline-block;
    border: 1px solid #e5e5e5;
    background: #fff;
    line-height: 32px;
    margin-right: 10px;
    padding: 0 20px;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    margin-bottom: 10px;
    color: #666;
    font-size: 14px;
}
.line {
    width: 1200px;
    margin: 0 auto;
    margin-top: 90px;
}

.line {
    height: 0;
    overflow: hidden;
    border-top: 1px solid #ddd;
}
.m-tabs-primary>.m-tabs-nav-wrap {
    height: 40px;
}
.m-tabs-nav-wrap {
    border-bottom: 1px solid #e5e5e5;
}
.m-tabs-primary>.m-tabs-nav-wrap>.m-tabs-nav>.m-tabs-tab {
    padding-left: 40px;
    padding-right: 40px;
    height: 40px;
    line-height: 36px;
    border-width: 3px 1px 1px;
    border-style: solid;
    border-color: transparent;
}
.m-tabs-primary>.m-tabs-nav-wrap>.m-tabs-nav>.m-tabs-tab.active {
    border-color: #f60 #e5e5e5 #fff;
    color: #f60;
    font-size: 16px;
}
.m-tabs-nav div {
    display: inline-block;
    zoom: 1;
}
.m-tabs-tab {
    float: left;
    font-size: 14px;
    color: #666;
    cursor: pointer;
}

.u-btn.u-btn-default-ghost {
    color: #ee8028;
    background: #fbf7f2;
    border: 1px solid #ee8028;
}
.u-btn.u-btn-lg {
    border-radius: 0;
    height: 50px;
    line-height: 48px;
    padding-left: 40px;
    padding-right: 40px;
    font-size: 18px;
}
.u-btn.u-btn-primary {
    background: #ee8028;
    border: 1px solid #ee8028;
}
.u-btn.u-btn-primary {
    background-color: #f60;
    border-color: #f60;
}
.u-btn {
    border-radius: 0px;
    margin-right: 30px;
    height: 48px;
    line-height: 48px;
    font-size: 18px;
}
.u-btn {
    display: inline-block;
    position: relative;
    padding-left: 30px;
    padding-right: 30px;
    height: 36px;
    line-height: 34px;
    font-size: 14px;
    border-style: solid;
    border-width: 1px;
    color: #fff;
    border-radius: 2px;
    -webkit-transition: .2s;
    -o-transition: .2s;
    transition: .2s;
}
.u-btn {
    border-radius: 0px;
    margin-right: 30px;
    height: 48px;
    line-height: 48px;
    font-size: 18px;
}
.det_icn {
    display: inline-block;
    zoom: 1;
    height: 23px;
    width: 28px;
    background: url(../assets/img/det_icn.png) no-repeat center center;
    vertical-align: text-bottom;
    margin-right: 5px;
}
</style>

<template>
	<!-- star -->

	<div class="homepage_wrap">
		<!-- banner图和导航栏 -->
		<div class="banner_nav">
			<!-- banner -->
			<div class="banner_nav_bg">
				<!-- <img @dragstart.prevent @contextmenu.prevent src="../assets/img/bg1.png"  class="banner_warp_img" alt="" /> -->
				<img :src="preloadedImages[0]" class="banner_warp_img" alt="Preloaded Image" />  
				<!-- <img v-if="isPreloaded" :src="preloadedImages[0]" alt="Preloaded Image" />  
				<div v-else-if="isLoading">Loading images...</div>  
				<div v-else>Failed to load images.</div> -->
			</div>
			<daohang></daohang>
			<!-- title -->
			<div class="banner_title_wrap">
				<div class="banner_title_center">
					<p class="companyName">{{ title }}</p>
					<div class="fade-in-out">
						<span style="font-size: 30px;margin-left: -5px;">求</span><span style="margin-left: -18px;">生存敬业爱岗</span>
						<span style="font-size: 30px;margin-left: -18px;">与</span><span style="margin-left: -18px;">公司共命运</span>
						<span>·</span>
						<span style="font-size: 30px;">谋</span><span style="margin-left: -18px;">发展开拓进取</span>
						<span style="font-size: 30px;margin-left: -18px;">创</span><span style="margin-left: -18px;">企业新局面</span>
						<!-- <span>·</span>谋发展开拓进取创企业新局面</div> -->
					</div>
					<div class="rotating-triangle">
						<img @dragstart.prevent @contextmenu.prevent :src="imageSrc" :style="imageStyle" alt="Rotatable Image" @click="startRotation" />
						<!-- <div class="line" :style="lineStyle"></div> -->
						<svg width="300" height="100" :style="lineStyle" class="polyline-svg">  
							<polyline  
								:points="polylinePoints"  
								stroke="black"  
								stroke-width="2"  
								fill="none"  
							/>
						</svg>
						<div v-if="currentText" class="overlay-text">{{ currentText }}<span><h6 style="width: 260px;padding-top: 5px;">{{ currentChildText }}</h6></span></div>
					</div>
				</div>
			</div>
		</div>
		<!-- 最新资讯 -->
		<!-- <div class="news">
			<div class="news_cont"> -->
				<!-- left -->
				<!-- <div class="news_cont_left">
					<h1>最新资讯</h1>
					<div class="news_bg">
						<div class="news_cont_left_img">
							<img loading="lazy" @dragstart.prevent @contextmenu.prevent src="../assets/img/news_img1.png" alt="" />
						</div>
						<div class="news_cont_left_text">
							<h1>要让学生知道，努力学习是一种责任！</h1>
							<p>2021-05-30</p>
							<p>
								中国心理学会法律心理学分会副会长、中国人民公安大学教授李玫瑾，同时也担任
							</p>
							<p>中国预防青少年犯罪研究会的副会长...</p>
						</div>
					</div>
				</div> -->
				<!-- right -->
				<!-- <div class="news_cont_right">
					<div class="news_cont_right_ul">
						<ul>
							<router-link to="/xinwen"> <li>公司新闻</li></router-link>
							<span>|</span>
							<li>媒体报道</li>
							<span>|</span>
							<li>行业资讯</li>
						</ul>
					</div>
					<div class="news_cont_right_text">
						<div
							class="news_cont_right_text_item news_cont_right_text_hr"
							v-for="(item, index) in newList"
							:key="index"
							@click="newsArticle(index)"
						>
							<h1>{{ item.title }}</h1>
							<p>{{ item.time }}</p>
							<p>
								{{ item.text }}
							</p>
						</div>
					</div>
				</div>
			</div>
		</div> -->
		<div class="AboutEnterprises_cont_bottom">  
			<!-- <div class="AboutEnterprises_cont_bottom_item">  
				<h1>{{ animatedIndustryCount }}</h1>  
				<div class="AboutEnterprises_cont_bottom_item_info">  
					<p>覆盖行业个数</p>  
				</div>  
			</div>  
			<div class="AboutEnterprises_cont_bottom_item">  
				<h1>{{ animatedAuctionAnalyses }}万</h1>  
				<div class="AboutEnterprises_cont_bottom_item_info">  
					<p>每天竞价分析次数</p>  
				</div>  
			</div>  
			<div class="AboutEnterprises_cont_bottom_item">  
				<h1>{{ animatedUserCount }}亿</h1>  
				<div class="AboutEnterprises_cont_bottom_item_info">  
					<p>累计服务用户人数</p>  
				</div>  
			</div>   -->
					<!-- <div
						class="AboutEnterprises_cont_bottom_item"
						style="border: 0; margin-right: -70px"
					>
						<h1>30</h1>
						<div class="AboutEnterprises_cont_bottom_item2">
							<span>+</span>
							<p>全国校区</p>
						</div>
					</div> -->
		</div>
		<!-- 公司产业 -->
		<div class="GroupIndustry">
			<div class="GroupIndustry_cont">
				<!-- 左 -->
				<div class="GroupIndustry_cont_left">
					<!-- title -->
					<div class="GroupIndustry_cont_left_title">
						<p>公司产业</p>
					</div>
					<!-- img -->
					<div class="GroupIndustry_cont_left_img_f">
						<div
							v-for="(item, index) in jituanList"
							:key="index"
							class="GroupIndustry_cont_left_img"
						>
							<img loading="lazy" @dragstart.prevent @contextmenu.prevent :src="item.jituanimg" />
							<img loading="lazy" @dragstart.prevent @contextmenu.prevent
								:src="item.jituanimgyinying"
								class="GroupIndustry_cont_left_img2"
							/>
							<div class="GroupIndustry_cont_left_text">
								<span>{{ item.jituanspan }}</span>
								<p>{{ item.jituanp }}</p>
							</div>
						</div>
					</div>
				</div>
				<!-- 右 -->
				<div class="GroupIndustry_cont_right">
					<div class="GroupIndustry_cont_right_img">
						<img loading="lazy" @dragstart.prevent @contextmenu.prevent src="../assets/img/jituanyou.png" alt="" />
						<img loading="lazy" @dragstart.prevent @contextmenu.prevent
							class="GroupIndustry_cont_right_img2" style="height: 35%;"
							src="../assets/img/jituanyinying.png"
							alt=""
						/>
					</div>
					<div class="GroupIndustry_cont_right_text">
						<p>视频运营</p>
						<span>视频策略运营 | 视频生态构建 | 视频营销专家</span>
					</div>
				</div>
			</div>
		</div>
		<div class="responsive-image-container">
			<!-- <img
				loading="lazy"
				@dragstart.prevent
				@contextmenu.prevent
				class="responsive-image"
				src="../assets/img/ofpay.jpg"
				alt="Optimized Image"
			/> -->
			<img :src="preloadedImages[1]" class="responsive-image" alt="Preloaded Image" /> 
		</div>
		<div class="Electricity_cont_item">
			<div class="Electricity_cont_item2" id="operate">
				<!-- <img
					loading="lazy"
					@dragstart.prevent
					@contextmenu.prevent
					src="../assets/img/dianshang/adfweertwrt3345143551jkhkjhfaodif.png"
					alt="System Stability"
				/> -->
				<img :src="preloadedImages[2]" class="responsive-image" alt="Preloaded Image" /> 
				<div class="Electricity_cont_item2_text">
					<h1>系统稳定高于一切，商家经营有保障</h1>
					<p>
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;强大的技术与数据支撑，国家级智能供应链保障，丰富的兑换商品管理体系，
						优质卡券 / 商品 / 服务支持一键上架，支持客户将自营商品设置为兑换商品，并进行预充值，用户兑换成功后由商城下单和发货，
						无需商家自建仓库与发货。
					</p>
					<button @click="goToBootmall" class="informations">了解详情</button>
				</div>
			</div>
			<div class="Electricity_cont_item1">
				<div class="Electricity_cont_item1_text">
					<h1>丰富的积分兑换玩法</h1>
					<p>
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;积分支持兑换实物商品 / 优惠券 / 虚拟卡券，海量优质商品任您选，打造积分赚取消耗闭环，让积分更有价值，提示用户活跃度
					</p>
					<button @click="goToJifen" class="informations1">了解详情</button>
				</div>
				<img
					loading="lazy"
					@dragstart.prevent
					@contextmenu.prevent
					src="../assets/img/dianshang/314e251f95cad1c89e82e0c267be5805c83d51a1.png"
					alt="Rich Exchange Options"
				/>
			</div>
			<div class="Electricity_cont_item4" id="information">
				<img
					loading="lazy"
					@dragstart.prevent
					@contextmenu.prevent
					src="../assets/img/dianshang/2762a42a3d64401340352781d28481451468.jpg"
					alt="Comprehensive Data"
				/>
				<div class="Electricity_cont_item4_text">
					<h1>全量数据，助力运营</h1>
					<p>
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;用户分层，丰富数据看板，深入用户运营场景；为您提供丰富的数据呈现方案、角色看板，满足您对用户数据的展示诉求，同时深入用户运营场景，提供灵活的用户分层管理功能，多方位触达、影响用户的行为提供积分通兑服务的同时，完成对用户信息的分析加工，实现对企业的二次赋能，形成完整的产业价值链
					</p>
					<button @click="goToYingxiao" class="informations1">了解详情</button>
				</div>
			</div>
		</div>
		<div class="Electricity_cont_item5">
			<div class="Electricity_cont_item5_text">
				精准营销高效引流，轻松做好用户增长
			</div>
			<div class="Electricity_cont_item6_text">
				将节日热点及传统文化相结合，演绎品牌营销新可能
			</div>
			<div class="box">
				<ul class="list">
					<li><img loading="lazy" @dragstart.prevent @contextmenu.prevent class="pic" src="../assets/img/bootmall/chunjie_0.png" alt=""/></li>
					<!-- <li><img loading="lazy" @dragstart.prevent @contextmenu.prevent class="pic" src="../../assets/img/bootmall/nvshengjie_1.png" alt=""/></li> -->
					<li><img loading="lazy" @dragstart.prevent @contextmenu.prevent class="pic" src="../assets/img/bootmall/qingmingjie_2.png" alt=""/></li>
					<li><img loading="lazy" @dragstart.prevent @contextmenu.prevent class="pic" src="../assets/img/bootmall/laodongjie_3.png" alt=""/></li>
					<li><img loading="lazy" @dragstart.prevent @contextmenu.prevent class="pic" src="../assets/img/bootmall/muqingjie_4.png" alt=""/></li>
					<li><img loading="lazy" @dragstart.prevent @contextmenu.prevent class="pic" src="../assets/img/bootmall/duanwujie_5.png" alt=""/></li>
					<li><img loading="lazy" @dragstart.prevent @contextmenu.prevent class="pic" src="../assets/img/bootmall/zhongqiujie_6.png" alt=""/></li>
					<li><img loading="lazy" @dragstart.prevent @contextmenu.prevent class="pic" src="../assets/img/bootmall/guoqingjie_7.png" alt=""/></li>
				</ul>
			</div>
		</div>
		<!-- <div>
			<img loading="lazy" @dragstart.prevent @contextmenu.prevent  style="width: 100%;height: 550px;" src="../assets/img/API.png" />
		</div> -->
		<div class="responsive-image-container">
			<!-- <img
				loading="lazy"
				@dragstart.prevent
				@contextmenu.prevent
				class="responsive-image"
				src="../assets/img/API.png"
				alt="Optimized Image"
			/> -->
			<img :src="preloadedImages[3]" class="responsive-image" alt="Preloaded Image" /> 
		</div>
		<!-- 合作伙伴 -->
		<div class="CooperativePartner">
			<div class="CooperativePartner_cont">
				<h1 class="CooperativePartner_cont_title">合作伙伴</h1>
			</div>
			<!-- <div class="CooperativePartner_cont_center">
				<div class="CooperativePartner_cont_center_item">
					<img loading="lazy" @dragstart.prevent @contextmenu.prevent src="../assets/img/合作1.png" alt="" />
					<span>合作客户</span>
				</div>
				<div class="CooperativePartner_cont_center_item">
					<img loading="lazy" @dragstart.prevent @contextmenu.prevent src="../assets/img/合作2.png" alt="" />
					<span>合作代理商</span>
				</div>
			</div> -->
			<div class="CooperativePartner_cont_bottom">
				<div
					v-for="(item, index) in logoList"
					:key="index"
					class="CooperativePartner_cont_bottom_logo"
				>
					<img loading="lazy" @dragstart.prevent @contextmenu.prevent :src="item.logo" alt="" />
				</div>
			</div>
		</div>
		<!-- 关于企业 -->
		<div class="AboutEnterprises">
			<div class="AboutEnterprises_cont">
				<!-- top -->
				<div class="AboutEnterprises_cont_top">
					<!-- top_left -->
					<div class="AboutEnterprises_cont_top_left"> 
						<h1>关于企业</h1>
						<p>
							&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;武汉鱼渊网络科技有限公司是一家集金融、科技、电商等为一体的公司。
							通过创新金融产品助力产业升级及国民经济转型，促进社会可持续发展；以科技创新推进智慧建设，
							以电商创新平台带动区域经济特色发展，以产业带动专业建设。公司秉承脚踏实地、志存高远的企业精神，落实内涵建设，坚持守正创新，
							致力于让人才更有价值，让科技、教育惠及大众，走出了一条特色化的发展道路。<br/>
							&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;公司涉及的业务包含互联网信息技术服务；电信业务代理；增值电信业务；数据技术开发；视频直播；物联网卡流量服务；广告设计、制作、代理、发布；市场营销策划等。
						</p>
						<router-link to="/jituangaikuo" class="custom-router-link"> 
							<div class="AboutEnterprises_cont_top_left_btn">
								<span>了解更多</span>
								<img loading="lazy" @dragstart.prevent @contextmenu.prevent src="../assets/img/guanyujiantou.png" alt="" />
							</div>
						</router-link>
					</div>
					<!-- top_right -->
					<div class="AboutEnterprises_cont_top_right">
						<img loading="lazy" @dragstart.prevent @contextmenu.prevent src="../assets/img/guanyuyou.png" alt="" />
						<!-- <div></div> -->
					</div>
				</div>
				<!-- bottom -->

			</div>
		</div>
		<!-- footer -->
		<footer2></footer2>

		<!-- end -->
	</div>
</template>

<script>
import daohang from "../components/nav.vue";
import footer2 from "../components/footer.vue";
export default {
	name: "index",
	components: {
		daohang,
		footer2,
	},
	data() {
		return {
			// navList: [
			// 	{ title: "首页", url: "/" },
			// 	{ title: "公司概况", url: "/jituangaikuo" },
			// 	// { title: "公司新闻", url: "/xinwen" },
			// 	// { title: "公司产业", url: "/chanye" },
			// 	// { title: "积分商城", url: "/bootmall" },
			// 	{ title: "云仓平台", url: "/bootflow" },
			// 	// { title: "企业福采", url: "/purchase" },
			// 	// { title: "招聘详情", url: "/zhaopinxiangqing0_0" },
			// 	{ title: "联系我们", url: "/lianxi" },
			// 	// { title: "招聘信息", url: "/zhaopinxinxi0" },
			// ],
			logoimg:require('@/assets/img/YUYUAN.png'),
			finalIndustryCount: 360,  
			currentIndustryCount: 0,  
			finalAuctionAnalyses: 3000, // 300万  
			currentAuctionAnalyses: 0, 
			finalUserCount: 4000, // 4亿
			currentUserCount: 0, 
			animationFrameId1: null,  
			animationFrameId2: null,  
			animationFrameId3: null,  
			imageSrc: require('@/assets/img/keji/编组 11.png'),
			rotationDegree: 0,
			rotationInterval: null,
			rotationCount: 0,
			texts: [
				'云安全',  
				'云存储',  
				'云服务',  
				'云网络',  
				'云数据库',  
			],  
			childTexts: [
				'提供高级别的安全防护，确保云端数据安全',  
				'超大容量存储空间，支持数据备份与恢复',  
				'灵活高效的云服务解决方案，满足多样化业务需求',  
				'构建稳定可靠的云网络环境，优化数据传输效率',  
				'高性能、可扩展的云数据库服务，支持快速数据访问', 
			], 
			currentText: '',
			currentChildText: '',
			lineLength: 0,
			lineAnimationDuration: 1000,

			polylinePoints: '100,50 100,50', 
			stepLength: 10,  
			currentLength: 0,  
			finalLength: 200,  
			polylinePointsArray: [{ x: 100, y: 50 }],
			imageUrls: [  
				require('@/assets/img/bg1.png'),
				require('@/assets/img/ofpay.jpg'),
				require('@/assets/img/dianshang/adfweertwrt3345143551jkhkjhfaodif.png'),
				require('@/assets/img/API.png'),
			],  
			preloadedImages: [],  
			isLoading: false,  
			isPreloaded: false,
			jituanList: [
				{
					jituanimg: require('@/assets/img/矩形@2x(5).png'),
					jituanimgyinying: require('@/assets/img/jituanyinying.png'),
					jituanspan: "积分商城",
					jituanp: "涵盖数字娱乐  |品质生活好物  |专属售后服务",
				},
				{
					jituanimg: require('@/assets/img/jituan1.png'),
					jituanimgyinying: require('@/assets/img/jituanyinying.png'),
					jituanspan: "云仓平台",
					jituanp: "快速充值服务  |多样会员选择  |安全卡密交易",
				},
				{
					jituanimg: require('@/assets/img/wulianwangka.png'),
					jituanimgyinying: require('@/assets/img/jituanyinying.png'),
					jituanspan: "物联网卡",
					jituanp: "高效流量管理  |便捷终端监控  |实时流量查询",
				},
				{
					jituanimg: require('@/assets/img/矩形@2x(6).png'),
					jituanimgyinying: require('@/assets/img/jituanyinying.png'),
					jituanspan: "网络技术服务",
					jituanp: "专业技术开发  |一站式技术服务  |前沿技术推广",
				}
			],
			logoList: [
				{ logo: require('@/assets/img/telecom_logo.png') },
				{ logo: require('@/assets/img/unicom_logo.png') },
				{ logo: require('@/assets/img/mobile_logo.png') },
				{ logo: require('@/assets/img/jd_logo.png') },
				{ logo: require('@/assets/img/meituan_logo.png') },
				// { logo: require('@/assets/img/njfh_logo.png') },
				{ logo: require('@/assets/img/wyyx_logo.png') },
				{ logo: require('@/assets/img/kfc_logo.png') },
				{ logo: require('@/assets/img/xbk_logo.png') },
				{ logo: require('@/assets/img/ireader_logo.png') },
				{ logo: require('@/assets/img/zhihu_logo.png') },
				{ logo: require('@/assets/img/shuqi_logo.png') },
				{ logo: require('@/assets/img/txsp_logo.png') },
				{ logo: require('@/assets/img/ruixing_logo.png') },
				{ logo: require('@/assets/img/wyy_logo.png') },
			],
			newList: [
				{
					title: "发展数字经济 共享包容增长 为经济发展插上“数字翅膀”",
					time: "2021-05-30",
					text: "中国心理学会法律心理学分会副会长、中国人民公安大学教授李玫瑾，同时也担任中国预防青少年犯罪研究会的副会长...",
				},
				{
					title: "用系统思维下好“职业教育一盘大棋”",
					time: "2021-05-30",
					text: "中国心理学会法律心理学分会副会长、中国人民公安大学教授李玫瑾，同时也担任中国预防青少年犯罪研究会的副会长...",
				},
				{
					title: "我国将在全国实施动产和权利担保统一登记—拓融资渠道",
					time: "2021-05-30",
					text: "中国心理学会法律心理学分会副会长、中国人民公安大学教授李玫瑾，同时也担任中国预防青少年犯罪研究会的副会长...",
				},
			],
		};
	},
	computed: {
		title() {
			return this.$store.state.title;
		},
		// animatedIndustryCount() {  
		// 	return (this.currentIndustryCount / 10).toFixed(0);  
		// },  
		// animatedAuctionAnalyses() {  
		// 	return (this.currentAuctionAnalyses / 10).toFixed(0);  
		// },  
		// animatedUserCount() {  
		// 	return (this.currentUserCount / 1000).toFixed(1);  
		// },
		imageStyle() {  
			return {  
				// 显式设置变换原点为图片中心（实际上是默认值）  
				transformOrigin: 'center center',  
				// 应用旋转变换  
				transform: `rotate(${this.rotationDegree}deg)`,  
				// 可选：添加平滑过渡效果  
				transition: 'transform 0.5s ease',  
			};  
		},
		lineStyle() {  
			return {  
				width: `${this.lineLength}px`, // 线段的宽度即为长度  
				height: '1px', // 线段的高度  
				backgroundColor: '#ffffff', // 线段的颜色  
				position: 'absolute',  
				top: '35%', // 根据需要调整位置  
				left: '50%', // 初始位置在图片中心，但会通过动画改变  
				transform: `translateX(-50%) translateY(-${this.imageHeight / 4 + 1}px)`, // 假设imageHeight是图片高度的一半加上一些偏移  
				transition: `width ${this.lineAnimationDuration}ms ease-out`, // 宽度变化的过渡效果  
			};  
		},
	},
	async mounted() {  
		this.isLoading = true; // 标记为正在加载  
		try {  
			await this.preloadImages(this.imageUrls);  
			this.isPreloaded = true; // 标记为已预加载  
		} catch (error) {  
			// 处理错误（例如，通过console.error或显示错误消息）  
			console.error('Failed to preload images:', error);  
		} finally {  
			this.isLoading = false; // 无论成功还是失败，都停止加载状态  
		}  
		this.startRotation();
		this.rotateImage();
		this.rotateLine();
		this.rotateText();
		this.updatePolylinePoints();
		// this.startAnimation();
		// this.startAnimation1();
    },
	methods: {
		funyuming(msg) {
			this.$router.push(msg);
		},
		newsArticle(index) {
			this.$router.push("/newsxiangqing" + index);
		},
		funRouter() {
			this.$router.push("/bootmall");
		},
		animate() {  
			// 计算当前值与最终值之间的差值  
			const delta = this.finalIndustryCount - this.currentIndustryCount;  
		
			// 如果差值大于0，则继续动画；否则停止  
			if (delta > 0) {  
				// 使用一个简单的缓动函数（这里是线性增长，但你可以使用更复杂的缓动函数）  
				// 这里每次增加的值是固定的，但为了平滑效果，可以设置为更小的值，并通过更多的帧来达到最终值  
				const step = Math.max(1, Math.floor(delta / 1000)); // 假设我们分100步完成动画  
				this.currentIndustryCount += step;  
		
				// 递归调用requestAnimationFrame  
				this.animationFrameId1 = requestAnimationFrame(this.animate);  
			}  

			const delta1 = this.finalAuctionAnalyses - this.currentAuctionAnalyses;  
			if (delta1 > 0) {  
				const step = Math.max(1, Math.floor(delta / 1000)); // 假设我们分100步完成动画  
				this.currentAuctionAnalyses += step;  
				this.animationFrameId2 = requestAnimationFrame(this.animate);  
			} 

			const delta2 = this.finalUserCount - this.currentUserCount;  
			if (delta2 > 0) {  
				const step = Math.max(1, Math.floor(delta / 1000)); // 假设我们分100步完成动画  
				this.currentUserCount += step;  
				this.animationFrameId3 = requestAnimationFrame(this.animate);  
			} 

		},  
		startAnimation1() {  
			setInterval(() => {  
				this.startAnimation();
			}, 10000);
		},
		startAnimation() {  
			this.currentIndustryCount = 0;  
			this.currentAuctionAnalyses = 0;  
			this.currentUserCount = 0;  
			
			cancelAnimationFrame(this.animationFrameId1);  
			this.animationFrameId1 = null;  
			cancelAnimationFrame(this.animationFrameId2);  
			this.animationFrameId2 = null;  
			cancelAnimationFrame(this.animationFrameId3);  
			this.animationFrameId3 = null;  
			this.animate();   
		}, 
		rotateImage() {  
			setTimeout(() => {  
				this.rotationDegree += 72;  
			}, this.lineAnimationDuration);
		}, 
		rotateLine() {  
			this.lineLength = 0;
			const finalLength = 130;
			setTimeout(() => {  
				this.lineLength = finalLength;  
			}, this.lineAnimationDuration);
		}, 
		rotateText() {
			setTimeout(() => {  
				if(this.rotationCount == 5) {
					this.rotationCount = 0;
				}
				this.rotationCount = (this.rotationCount + 1) % 6;  
				this.currentText = this.texts[this.rotationCount - 1];
				this.currentChildText = this.childTexts[this.rotationCount - 1];
				this.extendPolyline();  
			}, this.lineAnimationDuration);
		}, 
		startRotation() {  
			if (!this.rotationInterval) {  
				this.rotationInterval = setInterval(() => {  
					this.rotateImage();
				}, 5000);
				setInterval(() => {  
					this.rotateLine();
				}, 5000);
				setInterval(() => {  
					this.rotateText();
				}, 5000);
			}  
		}, 
		extendPolyline() {  
			// 如果当前长度小于最终长度，则继续延长  
			if (this.currentLength < this.finalLength) {  
				// 计算新点的位置（这里简单地在x轴上向右延长）  
				const newX = this.polylinePointsArray[this.polylinePointsArray.length - 1].x + this.stepLength;  
				const newPoint = { x: newX, y: 50 }; // 保持y坐标不变  
		
				// 将新点添加到折线点数组中  
				this.polylinePointsArray.push(newPoint);  
		
				// 更新当前长度  
				this.currentLength += this.stepLength;  
		
				// 更新polyline的points属性  
				this.updatePolylinePoints();  
			}  
		},  
		updatePolylinePoints() {  
		// 将polylinePointsArray转换为适合polyline的points属性格式的字符串  
		this.polylinePoints = this.polylinePointsArray.map(point => `${point.x},${point.y}`).join(' ');  
		},
		goToBootmall() {  
			this.$router.push('/bootmall');
		},
		goToJifen() {  
			this.$router.push('/jifenyunying');
		},
		goToYingxiao() {  
			this.$router.push('/hudongyingxiao');
		},
		preloadImage(url) {  
			return new Promise((resolve, reject) => {  
				const img = new Image();  
				img.onload = () => resolve(url);  
				img.onerror = (error) => reject(error);  
				img.src = url;  
			});  
		},  
		async preloadImages(urls) {  
			for (const url of urls) {  
				await this.preloadImage(url);  
				this.preloadedImages.push(url); // 加载完成后添加到数组  
			}  
		},
	},
	
	beforeDestroy() {  
		if (this.animationFrameId1) {  
			cancelAnimationFrame(this.animationFrameId1);  
			this.animationFrameId1 = null;  
		}   
		if (this.animationFrameId2) {  
			cancelAnimationFrame(this.animationFrameId2);  
			this.animationFrameId2 = null;  
		}  
		if (this.animationFrameId3) {  
			cancelAnimationFrame(this.animationFrameId3);  
			this.animationFrameId3 = null;  
		}  
		if (this.rotationInterval) {  
			clearInterval(this.rotationInterval);  
			this.rotationInterval = null;  
		}
	},
};
</script>
<style scoped>
@charset "utf-8";
/*=========================Reset_start==========================*/
body,
h1,
h2,
h3,
h4,
h5,
h6,
div,
p,
dl,
dt,
dd,
ol,
ul,
li,
form,
table,
th,
td,
a,
img,
span,
strong,
var,
em,
input,
textarea,
select,
option {
	margin: 0;
	padding: 0;
}
/*去内外边距*/
html,
body {
	font-family: "微软雅黑", "宋体", Arail, Tabhoma;
	text-align: left;
}
/*字体 文本居左*/
ul {
	list-style: none;
}
/*去ul li 小点*/
img {
	border: 0 none;
}
/*img IE6 兼容问题*/
input,
select,
textarea {
	outline: 0;
}
/*去除点击蓝框*/
textarea {
	resize: none;
	overflow: auto;
}
/*锁定多行文本框大小 与文字超出出现滚动条*/
table {
	border-collapse: collapse;
	border-spacing: 0;
}
/*表格边框合并 边框间距0*/
th,
strong,
var,
em {
	font-weight: normal;
	font-style: normal;
}
/*特殊文字粗细 样式显示正常*/
a {
	text-decoration: none;
}
/*a标签去下划线*/
.clearfix:after {
	clear: both;
	content: "";
	display: block;
	height: 0;
}
/*清浮动*/
a {
	color: #000000;
}
/*==========================Reset_End===========================*/

.homepage_wrap {
	width: 100vw;
	margin: 0 auto;
	height: 1000px;
}

.banner_nav {
	width: 100vw;
	height: 800px;
	position: relative;
}

.banner_nav_bg img {
	/* 文本居顶部对齐 */
	vertical-align: top;
}

/* 导航部分 */


/* title */

.fade-in-out {  
  animation: fadeInOut 10s infinite alternate;  
  margin-top: 20px;
}  
  
@keyframes fadeInOut {  
  0% { opacity: 0; }  
  50% { opacity: 1; }  
  100% { opacity: 0; }  
}  

.rotating-triangle img {  
  width: 200px; /* 或者你想要的任何尺寸 */  
  height: auto;  
  display: block; /* 可选：防止图片下方有空隙 */  
  margin-top: -180px; /* 可选：水平居中图片 */  
  margin-left: 980px;
}
.overlay-text {  
  position: absolute;  
  top: 0;  
  right: 10;  
  margin-left: 1360px;  
  margin-top: 75px;  
  /* background-color: rgba(255, 255, 255, 0.7);   */
  color: rgba(255, 255, 255, 0.7);
  /* 可以添加其他样式，如字体大小、字体样式等 */  
}  
.line {  
  position: absolute;  
  margin-top: 70px;
  margin-left: 450px;  
}  
.polyline-svg {  
	margin-top: 45px;
	margin-left: 460px;  
	position: absolute;  
	top: 0;  
	left: 0; /* 根据需要调整SVG的位置 */  
	width: 300px; /* 根据需要调整SVG的宽度 */  
	height: 100px; /* 与容器高度一致 */  
} 
  
.banner_title_wrap {
	width: 100%;
	position: absolute;
	bottom: 290px;
	/* border: 1px solid yellow; */
}
.banner_title_center {
	/* border: 1px solid white; */
	text-align: left;
	width: 1500px;
	margin: 0 auto;
	font-size: 90px;
	font-family: AlibabaPuHuiTi-Bold, AlibabaPuHuiTi;
	font-weight: bold;
	color: #ffffff;
	line-height: 124px;
	letter-spacing: 1px;
	position: relative;
}
.companyName {  
    text-align: left;  
    width: 1200px;  
    margin: 0 auto;  
    font-size: 50px;  
    font-family: 'AlibabaPuHuiTi-Bold', sans-serif; /* 确保后备字体是有效的 */  
    font-weight: bold;  
    color: #ffffff;  
    line-height: 1.2; /* 使用相对行高以更灵活地适应不同字号 */  
    letter-spacing: 11.5px; /* 微调文字间距 */  
    position: relative;  
    text-shadow: 4px 4px 8px rgba(0, 0, 0, 5.5); /* 添加简单的文本阴影 */  
}

.banner_warp_img{
	width: 100vw;
	z-index: -999;
	position: absolute;
	/* height: 300px; */
}

.banner_title_center div {
	padding-top: 14px;
	font-size: 20px;
	font-family: Helvetica;
	line-height: 24px;
	letter-spacing: 4px;
}

.banner_title_center div span {
	margin: 0 18px;
}
/* news */
.news {
	width: 100vw;
	height: 801px;
	background: #f8f8f8;
}
.news_cont {
	width: 1200px;
	margin: 0 auto;
	display: flex;
	justify-content: space-between;
}
.news_cont_left {
	margin-top: 89px;
}
.news_cont_left_img img {
	width: 588px;
	height: 353px;
	margin-top: 41px;
}
.news_cont_left_text {
	padding-top: 27px;
	padding-left: 40px;
	width: 548px;
	height: 150px;
	background-color: #ffffff;
}
.news_cont_left_text h1 {
	font-size: 18px;
	font-weight: 500;
	color: #23337f;
}
.news_cont_left_text p {
	font-size: 14px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #8f8f8f;
	margin-top: 10px;
}
.news_cont_right {
	width: 588px;
	margin-top: 103px;
}
.news_cont_right_ul ul {
	font-size: 16px;
	font-weight: 400;
	color: #b3b3b3;
	line-height: 22px;
	float: right;
	display: flex;
}
.news_cont_right_ul ul a {
	font-size: 16px;
	font-weight: 400;
	color: #b3b3b3;
	line-height: 22px;
	float: right;
	display: flex;
}
.news_cont_right_ul ul li:hover {
	color: #23337f;
}
.news_cont_right_ul ul span {
	margin: 0 23px 0 24px;
}
.news_cont_right_text {
	width: 588px;
	height: 535px;
	background: #ffffff;
	margin-top: 68px;
}
.news_cont_right_text_item {
	padding: 36px;
}
.news_cont_right_text_item h1 {
	font-size: 18px;
	font-family: PingFangSC-Medium, PingFang SC;
	font-weight: 500;
	color: #444444;
	line-height: 25px;
}
.news_cont_right_text_item p {
	margin-top: 10px;
	font-size: 14px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #8f8f8f;
	line-height: 20px;
}
.news_cont_right_text_hr {
	/* float: right; */
	width: 508px;

	border-bottom: 1px solid #d7d7d7;
}
.news_cont_right_text_hr:nth-of-type(3) {
	/* float: right; */
	width: 508px;

	border-bottom: none;
}
.news_cont_right_text_item2 {
	padding: 24px 40px;
}
.news_cont_right_text_item2 h1 {
	font-size: 18px;
	font-family: PingFangSC-Medium, PingFang SC;
	font-weight: 500;
	color: #444444;
	line-height: 25px;
}
.news_cont_right_text_item2 p {
	margin-top: 10px;
	font-size: 14px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #8f8f8f;
	line-height: 20px;
}
/* 集团产业 */
.GroupIndustry {
    position: relative;
    width: 100vw;
    background: #ffffff;
    padding: 50px 0;
	padding-top: 0px;
}

.GroupIndustry_cont {
    width: 90%;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
}

.GroupIndustry_cont_left_title {
    font-size: 2.25rem; /* 36px */
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #444444;
    line-height: 1.4;
    margin: 2rem 0;
    text-align: left;
    width: 100%;
}

.GroupIndustry_cont_left {
    width: 72%; /* 左侧两列图片占66.66%宽度 */
    /* display: flex; */
    /* flex-wrap: wrap; */
    /* gap: 24px; 设置图片间距 */
}

.GroupIndustry_cont_left_img_f {
    width: 98%; /* 左侧两列图片占66.66%宽度 */
    display: flex;
    flex-wrap: wrap;
    gap: 24px; /* 设置图片间距 */
}

.GroupIndustry_cont_left_img {
    width: calc(50% - 12px); /* 每张图片占一半宽度，中间留出间距 */
    position: relative;
}

.GroupIndustry_cont_left_img img:first-child {
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(0, 100, 255, 0) 0%, #000b6e 100%);
}

.GroupIndustry_cont_left_img2 {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
	height: 35%;
}

.GroupIndustry_cont_left_text {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%; /* 文字区域宽度为图片宽度 */
    padding: 16px;
    box-sizing: border-box;
    color: #ffffff;
}

.GroupIndustry_cont_left_text p {
    font-size: 0.875rem; /* 14px */
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    line-height: 1.4;
    margin-top: 10px;
}

.GroupIndustry_cont_left_text span {
    font-size: 1.5rem; /* 24px */
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    text-shadow: 0px 2px 6px rgba(0, 0, 0, 0.3);
}

/* 右侧图片 */
.GroupIndustry_cont_right {
    width: 35%; /* 右侧图片占35%宽度 */
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: auto; /* 让右侧图片与左侧图片对齐 */
    margin-top: 110px; /* 将 padding 改为 margin */
    margin-left: 5px;
    padding: 0; /* 去除 padding */
}

.GroupIndustry_cont_right_img {
    width: 100%; /* 调整宽度为 100% */
    height: auto; /* 调整高度以与左侧对齐 */
    position: relative;
}

.GroupIndustry_cont_right_img img {
    width: 100%;
    height: 100%;
    /* object-fit: cover;  */
}

.GroupIndustry_cont_right_img2 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.GroupIndustry_cont_right_text {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 16px;
    box-sizing: border-box;
    color: #ffffff;
}

.GroupIndustry_cont_right_text p {
    font-size: 1.5rem; /* 24px */
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    line-height: 1.4;
}

.GroupIndustry_cont_right_text span {
    font-size: 0.875rem; /* 14px */
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    line-height: 1.4;
}

/* 浮动效果 */  
.GroupIndustry_cont_left_img:hover {  
    transform: translateY(-10px); 
    transition: transform 0.3s ease; 
    box-shadow: 0 4px 6px rgba(0,0,0,0.1); 
}

/* 图片刷新效果 */  
@keyframes refreshAnimation {  
    0%, 100% { opacity: 1; transform: scale(1.1); }  
    50% { opacity: 0.5; transform: scale(1.05); }  
}

.GroupIndustry_cont_left_img img {  
    transition: opacity 0.5s, transform 0.5s; 
}

.GroupIndustry_cont_left_img:hover img {  
    animation: refreshAnimation 1s;  
    animation: refreshAnimation 1s 1; 
    animation-fill-mode: forwards; 
}

.GroupIndustry_cont_left_img img {  
    animation-play-state: paused;  
    animation-fill-mode: forwards; 
}

.GroupIndustry_cont_left_img:hover img {  
    animation-play-state: running;  
}

/* 右侧浮动效果 */  
.GroupIndustry_cont_right:hover {  
    transform: translateY(-10px);
    transition: transform 0.3s ease;
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
}

.GroupIndustry_cont_right img {  
    transition: opacity 0.5s, transform 0.5s;
}

.GroupIndustry_cont_right:hover img {
    animation: refreshAnimation 1s;
    animation: refreshAnimation 1s 1;
    animation-fill-mode: forwards;
}

.GroupIndustry_cont_right img {  
    animation-play-state: paused;  
    animation-fill-mode: forwards;
}

.GroupIndustry_cont_right:hover img {  
    animation-play-state: running;  
}

/* 响应式 */
@media screen and (max-width: 768px) {
    .GroupIndustry_cont {
        flex-direction: column;
    }

    .GroupIndustry_cont_left_img_f,
    .GroupIndustry_cont_right {
        width: 100%;
    }

    .GroupIndustry_cont_left_img {
        width: 100%;
    }

    .GroupIndustry_cont_right {
        margin-top: 24px;
    }

    .GroupIndustry_cont_left_text span {
        font-size: 1.25rem; /* 20px */
    }

    .GroupIndustry_cont_left_text p {
        font-size: 0.75rem; /* 12px */
    }

    .GroupIndustry_cont_right_text p {
        font-size: 1.25rem; /* 20px */
    }

    .GroupIndustry_cont_right_text span {
        font-size: 0.75rem; /* 12px */
    }
}


/* 容器样式 */
.responsive-image-container {
    width: 100%;
    max-width: 100%; /* 限制最大宽度 */
    margin: 0 auto; /* 居中显示 */
    overflow: hidden; /* 防止图片溢出容器 */
}

/* 图片样式 */
.responsive-image {
    width: 100%;
    height: 550px; /* 高度自适应，保持图片比例 */
}

/* 针对不同屏幕的响应式设计 */
@media (max-width: 1200px) {
    .responsive-image-container {
        max-width: 100%;
    }

    .responsive-image {
        height: 500px; /* 中等屏幕时的高度 */
    }
}

@media (max-width: 768px) {
    .responsive-image {
        height: 400px; /* 小屏幕时的高度 */
    }
}

@media (max-width: 480px) {
    .responsive-image {
        height: 300px; /* 超小屏幕时的高度 */
    }
}


/* 关于企业 */
.AboutEnterprises {
	width: 100vw;
	height: 756px;
	background-image: url("../assets/img/guanyu_bg.png");
}
.AboutEnterprises_cont {
	width: 1200px;
	margin: 0 auto;
	height: 100%;
}
.AboutEnterprises_cont_top {
	display: flex;
	justify-content: space-between;
}
.AboutEnterprises_cont_top_left {
	margin-top: 143px;
	width: 600px;
}
.AboutEnterprises_cont_top_left > h1 {
	font-size: 36px;
	font-family: PingFangSC-Semibold, PingFang SC;
	font-weight: 600;
	color: #ffffff;
	line-height: 50px;
}
.AboutEnterprises_cont_top_left p {
	margin-top: 36px;

	font-size: 16px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #ffffff;
	line-height: 40px;
}

.custom-router-link { 
    display: inline-block;
    text-decoration: none; 
}  
.custom-router-link:hover .AboutEnterprises_cont_top_left_btn {  
    background-color: #7ab8eb; /* 示例背景色 */  
    color: #333;  
    transform: translateY(-2px); /* 上浮效果 */  
    box-shadow: 0 2px 4px rgba(0,0,0,0.1); /* 可选：添加阴影效果以增强上浮的视觉感 */  
    transition: transform 0.3s ease, background-color 0.3s ease, box-shadow 0.3s ease; /* 平滑过渡效果 */  
}  
  
.custom-router-link:hover .AboutEnterprises_cont_top_left_btn span {  
    color: #1c60df; /* 悬停时的文本颜色，根据需要调整 */  
}  
  
.AboutEnterprises_cont_top_left_btn {
	width: 160px;
	height: 40px;
	border: 1px solid rgba(255, 255, 255, 0.8);
	margin-top: 81px;
	display: flex;
	align-items: center;
}
.AboutEnterprises_cont_top_left_btn img {
	width: 24px;
	height: 24px;

	margin-left: 8px;
}
.AboutEnterprises_cont_top_left_btn span {
	margin-left: 32px;
	font-size: 16px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #ffffff;
	line-height: 22px;
}
.AboutEnterprises_cont_top_right {
	position: relative;
	width: 487px;
	height: 517px;
	margin-top: 115px;
}
.AboutEnterprises_cont_top_right img {
	position: absolute;
	z-index: 1;
}
.AboutEnterprises_cont_top_right Div {
	width: 317px;
	height: 408px;
	background: #3491e5;
	position: absolute;
	top: 64px;
	left: -33px;
}
/* AboutEnterprises_cont_bottom 样式 */  
.AboutEnterprises_cont_bottom {  
    display: flex;  
    flex-wrap: wrap;  
    justify-content: space-between;  
    margin: 0 auto; /* 可选：水平居中 */  
    max-width: 1300px; /* 增加了100px的最大宽度 */  
    box-sizing: border-box; /* 确保内边距和边框包含在总宽度内 */  
	padding-bottom: 0px;
	padding-top: 20px;
}  
.AboutEnterprises_cont_bottom p {
	font-size: 20px;
	font-family: PingFangSC-Medium, PingFang SC;
	font-weight: 500;
	color: black;
}
.AboutEnterprises_cont_bottom h1 {
	font-size: 60px;
	font-family: PingFangSC-Medium, PingFang SC;
	font-weight: 500;
	color: #171075ea;;
	line-height: 84px;
}
  
/* AboutEnterprises_cont_bottom_item 样式 */  
.AboutEnterprises_cont_bottom_item {  
    flex: 1 1 calc(33.333% - 20px); /* Flex子项的基础大小 */  
    margin-right: 20px;  
    margin-bottom: 20px;  
    text-align: center;  
    border: 1px solid #eee;  
    padding: 20px;  
    box-sizing: border-box;  
}  
  
/* 移除最后一个子项的右边距 */  
.AboutEnterprises_cont_bottom_item:nth-child(3n) {  
    margin-right: 0;  
}  
  
/* 响应式设计 */  
@media (max-width: 992px) {  
    .AboutEnterprises_cont_bottom_item {  
        flex: 1 1 calc(50% - 20px); /* 在中等屏幕上，每行显示两个子项 */  
    }  
  
    .AboutEnterprises_cont_bottom_item:nth-child(3n) {  
        margin-right: 20px; /* 重置右边距 */  
    }  
  
    .AboutEnterprises_cont_bottom_item:nth-child(even) {  
        margin-right: 0; /* 移除偶数子项的右边距 */  
    }  
}  
  
@media (max-width: 600px) {  
    .AboutEnterprises_cont_bottom_item {  
        flex: 1 1 100%; /* 在小屏幕上，每行显示一个子项 */  
        margin-right: 0;  
    }  
  
    /* 可选：调整小屏幕上的字体大小 */  
    .AboutEnterprises_cont_bottom_item h1 {  
        font-size: 32px; /* 减小标题字体大小 */  
    }  
  
    .AboutEnterprises_cont_bottom_item_info p {  
        font-size: 16px; /* 减小信息字体大小 */  
    }  
}
/* 合作伙伴 */
.CooperativePartner {
	width: 100vw;
	height: 648px;
	background-image: url("../assets/img/hezuo_bg.png");
}
.CooperativePartner_cont {
	width: 1200px;
	margin: 0 auto;
}
.CooperativePartner_cont_title {
	text-align: center;
	margin-top: 80px;
	font-size: 36px;
	font-family: PingFangSC-Semibold, PingFang SC;
	font-weight: 600;
	color: #444444;
	line-height: 50px;
}
.CooperativePartner_cont_center {
	display: flex;
	justify-content: space-between;
	width: 365px;
	height: 69px;

	margin: 0 auto;
	margin-top: 47px;
}
.CooperativePartner_cont_center_item {
	display: flex;
	align-items: center;
}
.CooperativePartner_cont_center_item:hover {
	border-bottom: 1px solid #3491e5;
}
.CooperativePartner_cont_center_item img {
	width: 40px;
	height: 40px;
}
.CooperativePartner_cont_center_item span {
	line-height: 69px;
	font-size: 24px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #23337f;
	line-height: 33px;
	margin-left: 14px;
}
.CooperativePartner_cont_bottom {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	width: 1200px;
	height: 392px;
	margin: 0 auto;
	margin-top: 80px;
}
.CooperativePartner_cont_bottom_logo img {
	width: 227px;
	height: 120px;
	background: #ffffff;
	box-shadow: 0px 0px 12px -2px rgba(0, 0, 0, 0.12);
	border-radius: 9px;
	margin-bottom: 16px;
}

.CooperativePartner_cont_bottom_logo:hover {  
    transform: translateY(-10px);
    transition: transform 0.3s ease;
    box-shadow: 0 0px 0 0px rgba(0,0,0,0.1);
}  

.CooperativePartner_cont_bottom_logo img {  
    transition: opacity 0.5s, transform 0.5s;
}  
  
.CooperativePartner_cont_bottom_logo:hover img {
    animation: refreshAnimation 1s;
    animation: refreshAnimation 1s 1;
    animation-fill-mode: forwards;
}  

.CooperativePartner_cont_bottom_logo img {  
    animation-play-state: paused;  
    animation-fill-mode: forwards;
}  
  
.CooperativePartner_cont_bottom_logo:hover img {  
    animation-play-state: running;  
}

.dropbtn {
	background-color: #fff;
	color: rgb(12, 11, 11);
	padding: 16px;
	font-size: 16px;
	border: none;
	cursor: pointer;
}

.dropdown {
	top: 23px;
	left: 460px;
	position: relative;
	display: inline-block;
}

.dropdown-content {
	z-index: 999;
	font-size: 14px;
	text-align: center;
	display: none;
	position: absolute;
	background-color: #f9f9f9;
	min-width: 300px;
	min-height: 120px;
	box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}

.dropdown-content a {
	color: rgb(6, 6, 6);
	padding: 12px 16px;
	text-decoration: none;
	display: block;
}

.dropdown-content a:hover {
	background-color: #f1f1f1;
}

.dropdown:hover .dropdown-content {
	display: block;
}



/* 公共样式 */
.Electricity_cont_item {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    padding: 20px;
}

/* .Electricity_cont_item1 样式 */
.Electricity_cont_item1 {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    background: #f8f8f8;
    /* padding: 20px; */
    border-radius: 8px;
    overflow: hidden;
	width: 65%;
}

.Electricity_cont_item1_text {
    flex: 1;
    padding: 40px;
}

.Electricity_cont_item1_text > h1 {
    margin-top: 20px;
    font-size: 24px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #444444;
    line-height: 33px;
}

.Electricity_cont_item1 p {
    margin-top: 30px;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #444444;
    line-height: 32px;
}

.Electricity_cont_item1 img {
    flex: 1;
    max-width: 500px;
    margin: auto;
	height: 100%;
}


/* .Electricity_cont_item2 样式 */
.Electricity_cont_item2 {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 20px;
    margin-top: 20px;
    padding: 20px;
	width: 65%;
}

.Electricity_cont_item2 img {
    flex: 1;
    max-width: 500px;
}

.Electricity_cont_item2_text {
    flex: 1;
    background: #ffffff;
    padding: 40px;
    border-radius: 8px;
}

.Electricity_cont_item2_text > h1 {
    font-size: 24px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #444444;
    line-height: 33px;
}

.Electricity_cont_item2_text p {
    margin-top: 19px;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
    line-height: 32px;
    padding-left: 10px;
}

/* .Electricity_cont_item4 样式 */
.Electricity_cont_item4 {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 20px;
    padding: 20px;
    border-radius: 8px;
    overflow: hidden;
	width: 65%;
}

.Electricity_cont_item4 img {
    flex: 1;
    max-width: 500px;
    transition: transform 0.3s ease;
}

.Electricity_cont_item4_text {
    flex: 1;
    background: #ffffff;
    padding: 40px;
    border-radius: 8px;
}

.Electricity_cont_item4_text > h1 {
    font-size: 24px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #444444;
    line-height: 33px;
}

.Electricity_cont_item4_text p {
    margin-top: 19px;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
    line-height: 32px;
    padding-left: 10px;
}

/* 响应式设计 */
@media (max-width: 1200px) {
    .Electricity_cont_item1_text,
    .Electricity_cont_item2_text,
    .Electricity_cont_item4_text {
        padding: 20px;
    }

    .Electricity_cont_item1 img,
    .Electricity_cont_item2 img,
    .Electricity_cont_item4 img {
        max-width: 400px;
    }
}

@media (max-width: 768px) {
    .Electricity_cont_item {
        padding: 10px;
    }

    .Electricity_cont_item1,
    .Electricity_cont_item2,
    .Electricity_cont_item4 {
        flex-direction: column;
        align-items: center;
    }

    .Electricity_cont_item1_text,
    .Electricity_cont_item2_text,
    .Electricity_cont_item4_text {
        text-align: center;
    }

    .Electricity_cont_item1 img,
    .Electricity_cont_item2 img,
    .Electricity_cont_item4 img {
        max-width: 300px;
    }
}

@media (max-width: 480px) {
    .Electricity_cont_item1_text > h1,
    .Electricity_cont_item2_text > h1,
    .Electricity_cont_item4_text > h1 {
        font-size: 20px;
    }

    .Electricity_cont_item1 p,
    .Electricity_cont_item2 p,
    .Electricity_cont_item4 p {
        font-size: 14px;
    }
}


.informations {  
    background-color: #007bff; /* 蓝色背景 */  
    color: white; /* 白色文字 */  
    border: none; /* 去除边框 */  
    border-radius: 5px; /* 圆角边框 */  
    padding: 10px 20px; /* 内边距 */  
    font-size: 16px; /* 字体大小 */  
    cursor: pointer; /* 鼠标悬停时显示指针，表明可点击 */  
    transition: background-color 0.3s; /* 背景色渐变效果 */  
    outline: none; /* 去除点击时的焦点轮廓 */  
	margin-left: 40px;
	margin-top: 60px;
}  
  
.informations:hover {  
    background-color: #0056b3; /* 鼠标悬停时改变背景色 */  
} 


.informations1 {  
    background-color: #007bff; /* 蓝色背景 */  
    color: white; /* 白色文字 */  
    border: none; /* 去除边框 */  
    border-radius: 5px; /* 圆角边框 */  
    padding: 10px 20px; /* 内边距 */  
    font-size: 16px; /* 字体大小 */  
    cursor: pointer; /* 鼠标悬停时显示指针，表明可点击 */  
    transition: background-color 0.3s; /* 背景色渐变效果 */  
    outline: none; /* 去除点击时的焦点轮廓 */  
	margin-left: 60px;
	margin-top: 30px;
}  
  
.informations1:hover {  
    background-color: #0056b3; /* 鼠标悬停时改变背景色 */  
} 


.Electricity_cont_item5 {
width: 100%;
min-width: 1200px;
height: 700px;
min-height: 700px;
margin: 0 auto;
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: center;
border-top: 1px solid hsla(0,0%,91%,.5);
}

.Electricity_cont_item5_text {
margin: 53px 0 20px;
/* width: 100%; */
height: 48px;
font-size: 34px;
font-weight: 500;
color: #2d2d2d;
}

.Electricity_cont_item6_text {
padding: 0 60px;
margin-bottom: 60px;
/* width: 1159px; */
min-height: 28px;
font-size: 20px;
font-weight: 400;
color: #2d2d2d;
line-height: 28px;
}
.pic {
width: 200px;
height: 430px;
}
.box {
margin-top: 100px;
margin-left: 150px;
position: absolute;
/* 让整个盒子居中 */
transform: translate(-10%, 20%);
width: 1000px;
height: 450px;
/* 溢出隐藏 */
overflow: hidden;
}

.list {
margin-left: 300px;
margin-right: 400px;
position: absolute;
/* top: 0;
left: 0; */
/* 设置ul更大的宽度,让子元素li可以并排浮动 */
width: 4000px;
height: 450px;
}

.list li {
/* 让每一个li标签并排浮动 */
margin-left: 30px;
float: left;
width: 300px;
height: 450px;
/* 使用animation标签，设置动画名称 时间 循环次数（此处为无限循环） 匀速 */
animation: move 10s infinite linear;
}

/* 定义动画 */
@keyframes move {

/* 第1张 */
0% {
transform: translateX(0px);
}

/* 第2张 */
10% {
transform: translateX(-300px);
}
/* 设置同样的值让图片停留一会 */
20% {
transform: translateX(-300px);
}

/* 第3张 */
25% {
transform: translateX(-600px);
}
35% {
transform: translateX(-600px);
}

/* 第4张 */
40% {
transform: translateX(-900px);
}
50% {
transform: translateX(-900px);
}

/* 第5张 */
55% {
transform: translateX(-1200px);
}
65% {
transform: translateX(-1200px);
}

/* 第6张 */
70% {
transform: translateX(-1500px);
}
80% {
transform: translateX(-1500px);
}

/* 第7张 */
90% {
transform: translateX(-1800px);
}
100% {
transform: translateX(-1800px);
}

}
youjiaopage_wrap {
width: 100vw;
margin: 0 auto;
height: 1000px;
}
/* 响应式设计 */
@media screen and (max-width: 1200px) {
  .Electricity_cont_item5 {
    min-width: 100%;
    height: auto;
    min-height: auto;
  }
  .box {
    width: 100%;
    max-width: 100%;
    transform: none;
    margin-left: 0;
  }
  .pic {
    width: 150px;
    height: auto;
  }
  .list {
    width: calc(150px * 7 + 30px * 6); /* 根据新的图片宽度计算 */
  }
  .list li {
    flex: 0 0 150px;
  }
}
</style>

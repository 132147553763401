<template>
  <!-- star -->
  <div class="kejipage_wrap">
    <!-- <banner2 :bannerH="h1" :bannerP="P1" :bannerImg="Img1"></banner2> -->
    <!-- 面包屑 -->
    <div class="Crumbs">
      <div><a href="/index">返回首页</a>><span>企业福采</span></div>
    </div>
    <!-- nav -->
    <div class="nav">
      <div class="wrap">
        <div class="logo">
          <a href="/index"><img @dragstart.prevent @contextmenu.prevent :src="logoimg" /></a>
        </div>
        <div class="phone">
          <span>企业服务热线：027-87208838</span>
        </div>
      </div>
    </div>
    
    <div class="logon-content-wrap">
    <div class="container" style="min-height: 560px">
        <h2 class="title">企业用户注册</h2>
        <div class="line2 lc1 mt16"></div>
        <span id="tishi">请正确填写以下信息</span>
        <form action="/register/doRegister" method="post" id="registerForm">
            <input type="hidden" name="_csrf" value="d474251f-4124-41f4-be1a-d709db7c05eb">
            <div class="m-form m-form-label-tr mt50 j-form-wrap">
                <div class="form-group">
                    <label class="control-label col-12"><b style="color: #de3939">*</b>手机号码</label>
                    <div class="col-offset-12">
                        <input name="mobile" type="text" class="u-txt lg j-txt-phone error" placeholder="请输入手机号码" style="width: 378px;">
                        <p class="form-error j-error">请输入手机号</p>
                    </div>
                </div>
                <div class="form-group">
                    <label class="control-label col-12"><b style="color: #de3939">*</b>短信验证码</label>
                    <div class="col-offset-12">
                        <input name="code" type="text" class="u-txt lg j-txt-code" placeholder="请输入短信验证码" style="width: 238px;">
                        <a href="javascript:;" class="u-btn u-btn-default lg ml10 disabled j-get-code" style="width: 90px;">获取验证码</a>
                        <p class="form-error j-error"></p>
                    </div>
                </div>
                <div class="form-group">
                    <label class="control-label col-12"><b style="color: #de3939">*</b>企业名称</label>
                    <div class="col-offset-12">
                            <input name="enterpriseName" type="text" class="u-txt lg j-txt-companyName" placeholder="请输入所属企业名称" style="width: 378px;">
                        <p class="form-error j-error"></p>
                    </div>
                </div>
                <div class="form-group">
                    <label class="control-label col-12">邮箱</label>
                    <div class="col-offset-12">
                        <input name="email" class="u-txt lg j-txt-email" placeholder="选填，请输入您的邮箱" style="width: 378px;">
                        <p class="form-error j-error"></p>
                    </div>
                </div>
                <div class="form-group">
                    <label class="control-label col-12">岗位</label>
                    <div class="col-offset-12">
                        <input name="job" class="u-txt lg j-txt-job" placeholder="选填，请输入您的岗位" style="width: 378px;">
                        <p class="form-error j-error"></p>
                    </div>
                </div>
                <div class="form-group">
                    <label class="control-label col-12">邀请码</label>
                    <div class="col-offset-12">
                        <input name="refereeCode" id="refereeCode" type="text" class="u-txt lg j-recommend-code" placeholder="请输入邀请码" style="width: 378px;">
                        <p class="form-error j-error"></p>
                    </div>
                </div>

                <div class="form-group mt16">
                    <div class="col-offset-12">
                        <div class="final-error"><span id="errorInfo" style="display:none"></span></div>
                        <a href="javascript:;" class="u-btn u-btn-primary lg disabled btn-logon" id="logonBtn">注册</a>
                        <div class="clearfix mt10 lh20" style="width: 400px;">
                            <a href="/index" class="fr f12">已有账号，去登录</a>
                            <label class="u-checkbox j-checkbox">
                                <i class="ico"></i>
                                <input type="checkbox" name="protocol" value="1">
                                <span class="f12">我已阅读并同意《<a href="/register/protocol" target="_blank">用户服务协议</a>》</span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
    <!-- footer -->
    <footer2></footer2>

    <!-- end -->
  </div>
</template>

<script>
import footer2 from "../components/footer.vue";
// import banner2 from "../components/banner.vue";
// import Pages from "../components/recruitInfoPages.vue"
// import nav2 from "../components/nav.vue";
export default {
	name: "keji",
	components: {
		footer2,
		// nav2,
		// banner2,
		// Pages
	},
	data() {
		return {
			h1: "企业福采",
			P1: "帮助品牌方打通销售通路，提高数字商品流通效率；为消费端构建权益场景，提升用户活跃！",
			Img1: this.$imgUrl+"/yuyua/img/keji/20230705162508.jpg",
			logoimg: this.$imgUrl+"/yuyua/img/YUYUAN.png", 
			currentTab: 0,
            currentClass: 0,
			
			
    };
	},
	methods: {
		toggleTab(current) {
            this.currentTab = current;
            this.currentClass = current;
        },
        goodInfo(index){
          this.$router.push("/goodInfo/" + index);
        }
	}
};
</script>

<style scoped>
@charset "utf-8";
/*=========================Reset_start==========================*/
body,
h1,
h2,
h3,
h4,
h5,
h6,
div,
p,
dl,
dt,
dd,
ol,
ul,
li,
form,
table,
th,
td,
a,
img,
span,
strong,
var,
em,
input,
textarea,
select,
option {
	margin: 0;
	padding: 0;
}
/*去内外边距*/
html,
body {
	font-family: "微软雅黑", "宋体", Arail, Tabhoma;
	text-align: left;
}
/*字体 文本居左*/
ul {
	list-style: none;
}
/*去ul li 小点*/
img {
	border: 0 none;
}
/*img IE6 兼容问题*/
input,
select,
textarea {
	outline: 0;
}
/*去除点击蓝框*/
textarea {
	resize: none;
	overflow: auto;
}
/*锁定多行文本框大小 与文字超出出现滚动条*/
table {
	border-collapse: collapse;
	border-spacing: 0;
}
/*表格边框合并 边框间距0*/
th,
strong,
var,
em {
	font-weight: normal;
	font-style: normal;
}
/*特殊文字粗细 样式显示正常*/
a {
	text-decoration: none;
}
/*a标签去下划线*/
.clearfix:after {
	clear: both;
	content: "";
	display: block;
	height: 0;
}
/*清浮动*/
a {
	color: #000000;
}
/*==========================Reset_End===========================*/

.kejipage_wrap {
	width: 100vw;
	margin: 0 auto;
	height: 1000px;
}
/* 面包屑 */
.Crumbs {
	width: 1200px;
	height: 46px;
	margin: 0 auto;
}
.Crumbs {
	font-size: 12px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #999999;
	line-height: 17px;
}
.Crumbs div {
	line-height: 46px;
}
.Crumbs div a{

	color: #999;
	cursor: pointer;
}
.Crumbs span {
	color: #000000;
}
.nav{
	width: 100vw;
	box-shadow: 0px 0px 3px rgba(155, 155, 155, 0.1);

}
.wrap {
	width: 1200px;
	height: 101px;
	line-height: 101px;
	margin: 0 auto;
}

.wrap > .logo a > img {
	/* position: absolute; */
	/* left: 190px; */
	float: left;
	margin-top: 23px;
	width: 181px;
	/* height: 54px; */
}

.wrap .phone{
	/* width: 400px; */
	height: 101px;
	text-align: right;
}

/* 注册 */
.logon-content-wrap {
    background-color: #F7F7F7;
    padding: 40px 0 50px;
}

.logon-content-wrap .container {
    background-color: #fff;
    min-height: 738px;
    padding: 30px 40px 40px;
}

.logon-content-wrap .title {
    font-size: 20px;
    text-align: center;
    line-height: 28px;
    font-weight: normal;
}

.logon-content-wrap .m-form {
    margin-left: 100px;
    font-size: 16px;
}

.logon-content-wrap .form-group {
    margin-bottom: 6px;
    line-height: 50px;
}

.logon-content-wrap .form-group > div {
    padding-left: 10px;
}

.logon-content-wrap .form-error {
    font-size: 12px;
    color: #f00;
    margin-top: 4px;
    line-height: 20px;
    height: 20px;
}

.logon-content-wrap .u-btn.lg {
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 2px;
}

.btn-logon {
    width: 358px;
    border-radius: 0;
}
#tishi {
    font-size: 14px;
    color: #999999;
    display: block;
    width: 160px;
    background: #fff;
    margin: 0 auto;
    margin-top: -10px;
    text-align: center;
}
.logon-content-wrap .form-group {
    margin-bottom: 6px;
    line-height: 50px;
}
.clearfix, .wrap, .form-group, .m-tips {
    zoom: 1;
    display: block;
}
.m-form-label-tr .control-label {
    text-align: right;
}
.col-12 {
    width: 12em;
}
.control-label {
    color: #666;
    float: left;
    text-align: left;
}
.logon-content-wrap .form-group > div {
    padding-left: 10px;
}
.m-form-label-tr .form-group>div, .m-form-label-tr .final-error {
    padding-left: 20px;
}
.form-group>div {
    padding-left: 10px;
    position: relative;
}
.col-offset-12 {
    margin-left: 12em;
}
.u-txt.lg {
    font-size: 16px;
}
.u-txt.error {
    border-color: #f00;
}
.u-txt.lg {
    padding: 14px 10px;
}
.u-txt {
    padding: 7px 10px;
    border: 1px solid #e5e5e5;
    color: #666;
    width: 80px;
    vertical-align: middle;
    transition: .2s border-color;
    font-size: 14px;
    height: 20px;
 }   
    .logon-content-wrap .u-btn.lg {
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 2px;
}
.u-btn.disabled {
    background-color: #ededed;
    border-color: #ededed;
    color: #ccc;
    cursor: default;
}
.u-btn.lg {
    height: 48px;
    line-height: 48px;
    min-width: 60px;
    padding-left: 60px;
    padding-right: 60px;
    border-radius: 0;
    font-size: 18px;
}
.u-btn.lg {
    height: 48px;
    line-height: 48px;
    min-width: 60px;
    padding-left: 60px;
    padding-right: 60px;
    border-radius: 0;
    font-size: 18px;
}
.u-btn {
    display: inline-block;
    text-align: center;
    border-width: 1px;
    border-style: solid;
    vertical-align: middle;
    }
.btn-logon {
    width: 358px;
    border-radius: 0;
}

.ml10 {
    margin-left: 10px;
}
.logon-content-wrap .m-form {
    margin-left: 100px;
    font-size: 16px;
}
.mt50 {
    margin-top: 50px;
}
.logon-content-wrap .container {
    background-color: #fff;
    min-height: 738px;
    padding: 30px 40px 40px;
}
.container {
    zoom: 1;
    display: block;
}
.container {
    width: 980px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
}
.f12 {
    font-size: 12px;
}
.fr {
    float: right;
}
.fl, .fr {
    display: inline;
}
a {
    color: #2089ff;
    text-decoration: none;
}
.u-radio, .u-checkbox {
    position: relative;
    font-size: 14px;
    min-height: 20px;
    line-height: 20px;
    color: #666;
    display: inline-block;
    cursor: pointer;
    
}
.mt16 {
    margin-top: 16px;
}
.lc1 {
    border-color: #eee;
}
.line2 {
    border-bottom-width: 2px;
}
.line1, .line2 {
    height: 0;
    overflow: hidden;
    border-bottom-style: solid;
    border-color: #ddd;
}
</style>

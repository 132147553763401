<template>
	<div>
		<div class="wrap">
			<div class="logo" @click="goToHome">
				<img @dragstart.prevent @contextmenu.prevent src="../assets/img/YUYUAN.png" />
			</div>
			<div class="nav">
				<div class="dropdown">
					<button class="dropbtn">产品 & 解决方案
						<span class="caret"></span>
					</button>
					<div class="dropdown-content">
						<div class="category">  
							<div class="content-span">产品</div>
							<div style="padding-left: 80px;">解决方案</div>
							<!-- <div class="separator"></div>  -->
						</div>
						<!-- <div>
							<span><hr style="width:260px;border:1px groove;" /></span>
						</div> -->
						<div class="container">  
							<div class="content-span">  
								<router-link to="/jifenyunying">积分运营</router-link>  
							</div>  
							<div class="right-content">  
								<div class="image-container">  
									<img class="lingshouimg" src="../assets/img/lingshou.png" @dragstart.prevent @contextmenu.prevent />  
								</div>  
								<div class="right-span">  
									<router-link to="/lingshou">零售&nbsp;></router-link>  
								</div>  
							</div>  
						</div>
						<div class="container">  
							<div class="content-span">  
								<router-link to="/quanyizhongxin">权益中心</router-link>  
							</div>  
							<div class="right-content">  
								<div class="image-container">  
									<img class="lingshouimg" src="../assets/img/hulianwang.png" @dragstart.prevent @contextmenu.prevent />  
								</div>  
								<div class="right-span">  
									<router-link to="/hulianwang">互联网&nbsp;></router-link>  
								</div>  
							</div>  
						</div>
						<div class="container">  
							<div class="content-span">  
								<router-link to="/hudongyingxiao">互动营销</router-link>  
							</div>  
							<div class="right-content">  
								<div class="image-container">  
									<img class="lingshouimg" src="../assets/img/jinrong.png" @dragstart.prevent @contextmenu.prevent />  
								</div>  
								<div class="right-span">  
									<router-link to="/jinrong">金融&nbsp;></router-link>  
								</div>  
							</div>  
						</div>
					</div>
				</div>
				<ul>
					<li
						v-for="(item, index) in navList"
						:key="index"
						@click="funyuming(item.url)"
					>
						{{ item.title }}
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "Nav",
	data() {
		return {
			navList: [
				{ title: "首页", url: "/" },
				{ title: "公司概况", url: "/jituangaikuo" },
				{ title: "产品解决方案", url: "" },
				{ title: "积分商城", url: "/bootmall" },
				{ title: "云仓平台", url: "/bootflow" },
				// { title: "企业福采", url: "/purchase" },
				// { title: "招聘详情", url: "/zhaopinxiangqing0_0" },
				{ title: "联系我们", url: "/lianxi" },
				// { title: "注册", url: "/register" },
				// { title: "招聘信息", url: "/zhaopinxinxi0" },
				// { title: "公司产业", url: "/keji" },
				// { title: "公司新闻", url: "/xinwen" },
			],
			// logoimg: "../assets/img/YUYUAN.png",
			lingshouimg: "../assets/img/lingshou.png",
			hulianwangimg: "../assets/img/hulianwang.png",
			jinrongimg: "../assets/img/jinrong.png",

		};
	},
	methods: {
		funyuming(msg) {
			this.$router.push(msg);
		},
		funRouter() {
			this.$router.push("/bootmall");
		},
		goToHome() {  
			this.$router.push('/');
		}
	},
};
</script>
<style scoped>
.wrap {
	position: relative;
	width: 100vw;
	height: 80px;
	background-color: rgba(255, 255, 255, 1);
	box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.1);
}

.logo {  
  cursor: pointer;
} 

.wrap > .logo > img {
	position: absolute;
	left: 360px;
	top: -18px;
	width: 181px;
	height: 110px;
}

.nav {
	width: 1100px;
	position: absolute;
	left: 600px;
	top: -10px;
}

.nav ul {
	height: 100%;
	margin-top: -10px;
}

.nav ul li {
	height: 50px;
	float: left;
	display: flex;
	list-style-type: none;
	color: rgba(68, 68, 68, 1);
	font-size: 16px;
	margin-right: 80px;
	/* line-height: 30%; */
	cursor: pointer;
	align-items: center;
	margin-top: -15px;
}

.nav ul li:hover {
	color: rgba(35, 51, 127, 1);
	border-bottom: 2px solid rgba(35, 51, 127, 1);
}
.dropbtn {
	background-color: #fff;
	color: rgba(68, 68, 68, 1);
	padding: 16px;
	font-size: 16px;
	border: none;
	cursor: pointer;
	position: relative;
    display: inline-block;
	padding-left: 0px;
}

.caret {  
    display: inline-block;  
    width: 0;   
    height: 0;   
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 8px solid black;
    content: ""; 
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    transition: transform 0.3s ease;
}  
  
.dropbtn:hover .caret {  
    transform: rotate(180deg) translateY(0%);
}

.dropdown {
	top: 28px;
	left: 280px;
	position: relative;
	display: inline-block;
	font-size: 25px;
}

.dropdown-content {
	top: 100%;
	left: 0;
	transform: translateX(-35%);
	z-index: 999;
	font-size: 14px;
	text-align: center;
	display: none;
	position: absolute;
	background-color: #f9f9f9;
	min-width: 400px;
	min-height: 120px;
	box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
	border-radius: 5px;
	padding-top: 40px;
}

.dropdown-content a {
	color: black;
	padding: 12px 16px;
	text-decoration: none;
	display: block;
}

.content-span a:hover {
	background-color: #f1f1f1;
	border-radius: 5px;
}

.dropdown:hover .dropdown-content {
	display: block;
}

/* .right-content:hover {
	width: 200px;
	background-color: #f1f1f1;
	display: block;
} */

.content-span {
	margin-left: 20px;
	float: left;
}

.container {  
    display: flex;  
    align-items: center; /* 使内容垂直居中（如果需要的话） */  
}  
  
.right-content {  
	width: 200px;
    display: flex;  
    align-items: center; /* 垂直居中 */  
    position: relative; /* 以便为悬浮效果设置绝对定位 */  
    margin-left: 50px; /* 或其他适当的间距 */  
    /* 添加悬浮效果的背景色或其他装饰 */  
    transition: background-color 0.3s;  
}  
  
.right-content:hover {  
    background-color: #f0f0f0; /* 悬浮时的背景色 */  
    border-radius: 5px; /* 可选：添加边框圆角 */  
}  
  
.image-container img {  
    max-width: 100px; /* 或其他尺寸 */  
    height: auto;  
    margin-right: 0px; /* 图片和链接之间的间距 */  
}  

.category {  
    justify-content: space-between;  
    align-items: center;  
    margin-bottom: 20px; 
	padding-left: 18px;
	font-size: 18px;
	font-weight: 550;
}

.lingshouimg {  
	margin-left: 60px;
    display: inline-block; /* 使图片和相邻元素（如文字）在同一行显示 */  
    vertical-align: left; /* 垂直居中对齐图片与文字（可选，根据需要调整）   */
    width: 30px; /* 设置图片宽度，根据需要调整 */  
    height: auto; /* 保持图片的宽高比 */  
    /* 如果需要限制最大宽度，可以使用 max-width */  
    /* max-width: 100%; */  
    /* margin-right: 5px; /* 在图片和文字之间添加一些间隔（可选） */  
}


</style>

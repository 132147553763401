<template>
	<!-- star -->

	<div class="youjiaopage_wrap">
		<nav2></nav2>
		<banner2 :bannerH="h1" :bannerP="P1" :bannerImg="Img1"></banner2>
		<!-- 面包屑 -->
		<div class="Crumbs">
			<!-- <div>当前位置：首页><span>积分商城</span></div> -->
		</div>
		<!-- 电商板块 -->
		<div class="Electricity">
			<div class="Electricity_cont">
				<!-- <h1>电商板块</h1> -->
				<div class="Electricity_cont_item">
					<div class="Electricity_cont_item1" id="sector">
						<p>
							&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;随着信息时代的到来和电商行业的深度调整、发展，传统行业逐渐向新兴行业转变，更多的行业参与到越来越大众化的电商行业中来。
							鱼渊积分商城契合经济发展方向，以技术创新为驱动，以数字化管理为保障，汇集网店运营、新媒体营销等多样化的运营模式。
						</p>
						<img @dragstart.prevent @contextmenu.prevent
							src="../assets/img/dianshang/摄图网_500431060_banner.png"
							alt=""
						/>
					</div>
					<div class="Electricity_cont_item2" id="operate">
						<img @dragstart.prevent @contextmenu.prevent
							src="../assets/img/dianshang/摄图网_500548327_banner_商务金融云购物（非企业商用）.png"
							alt=""
						/>
						<div class="Electricity_cont_item2_text">
							<h1>系统稳定高于一切，商家经营有保障</h1>
							<p>
								&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;强大的技术与数据支撑，国家级智能供应链保障，丰富的兑换商品管理体系，
								优质卡券 / 商品 / 服务支持一键上架，支持客户将自营商品设置为兑换商品，并进行预充值，用户兑换成功后由商城下单和发货，
								无需商家自建仓库与发货。
							</p>
						</div>
					</div>
					<div class="Electricity_cont_item3" id="integral">
						<div class="Electricity_cont_item3_text">
							<h1>玩法多样，场景互通</h1>
							<p>
								&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;个性化玩法提升留存，经营场景打通，建立积分生态；多种积分玩法，如积分秒杀/团购，可快速根据企业特点打造积分活动，并通过多种渠道触达用户；企业内经营场景打通，积分互通互认，提高积分价值和用户体验。提供积分生态联盟解决方案，为用户增长建立新方向
							</p>
						</div>
						<img @dragstart.prevent @contextmenu.prevent
							src="../assets/img/dianshang/314e251f95cad1c89e82e0c267be5805c83d51a1.png"
							alt=""
						/>
					</div>
					<div class="Electricity_cont_item4" id="information">
						<img @dragstart.prevent @contextmenu.prevent
							src="../assets/img/dianshang/2762a42a3d64401340352781d28481451468.jpg"
							alt=""
						/>
						<div class="Electricity_cont_item4_text">
							<h1>全量数据，助力运营</h1>
							<p>
								&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;用户分层，丰富数据看板，深入用户运营场景；为您提供丰富的数据呈现方案、角色看板，满足您对用户数据的展示诉求，同时深入用户运营场景，提供灵活的用户分层管理功能，多方位触达、影响用户的行为提供积分通兑服务的同时，完成对用户信息的分析加工，实现对企业的二次赋能，形成完整的产业价值链
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>

		<footer2></footer2>

		<!-- end -->
	</div>
</template>

<script>
import footer2 from "../components/footer.vue";
import banner2 from "../components/banner.vue";
import nav2 from "../components/nav.vue";
export default {
	name: "keji",
	components: {
		footer2,
		nav2,
		banner2,
	},
	data() {
		return {
			h1: "积分商城",
			P1: "提供全周期用户积分运营解决方案",
			Img1: require('@/assets/img/keji/20230710163055.png'),
		};
	},
	methods: {},
};
</script>
<style scoped>
@charset "utf-8";
/*=========================Reset_start==========================*/
body,
h1,
h2,
h3,
h4,
h5,
h6,
div,
p,
dl,
dt,
dd,
ol,
ul,
li,
form,
table,
th,
td,
a,
img,
span,
strong,
var,
em,
input,
textarea,
select,
option {
	margin: 0;
	padding: 0;
}
/*去内外边距*/
html,
body {
	font-family: "微软雅黑", "宋体", Arail, Tabhoma;
	text-align: left;
}
/*字体 文本居左*/
ul {
	list-style: none;
}
/*去ul li 小点*/
img {
	border: 0 none;
}
/*img IE6 兼容问题*/
input,
select,
textarea {
	outline: 0;
}
/*去除点击蓝框*/
textarea {
	resize: none;
	overflow: auto;
}
/*锁定多行文本框大小 与文字超出出现滚动条*/
table {
	border-collapse: collapse;
	border-spacing: 0;
}
/*表格边框合并 边框间距0*/
th,
strong,
var,
em {
	font-weight: normal;
	font-style: normal;
}
/*特殊文字粗细 样式显示正常*/
a {
	text-decoration: none;
}
/*a标签去下划线*/
.clearfix:after {
	clear: both;
	content: "";
	display: block;
	height: 0;
}
/*清浮动*/
a {
	color: #000000;
}
/*==========================Reset_End===========================*/

.youjiaopage_wrap {
	width: 100vw;
	margin: 0 auto;
	height: 1000px;
}
/* 面包屑 */
.Crumbs {
	padding-top: 60px;
	width: 1200px;
	height: 46px;
	margin: 0 auto;
}
.Crumbs {
	font-size: 12px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #999999;
	line-height: 17px;
}
.Crumbs div {
	line-height: 46px;
}
.Crumbs span {
	color: #000000;
}
/* 电商板块 */
.Electricity_cont {
	width: 1200px;
	margin: 0 auto;
	height: 2240px;
}
.Electricity_cont > h1 {
	text-align: center;
	margin: 65px 0 45px 0;
}
.Electricity_cont_item1 {
	display: flex;
	justify-content: space-between;
	background: #f8f8f8;
}
.Electricity_cont_item1 p {
	margin-top: 30px;
	width: 600px;
	height: 240px;
	font-size: 16px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #444444;
	line-height: 40px;
	padding-left: 50px;
	padding-top: 40px;
}
/* .Electricity_cont_item1_text {
	position: absolute;
	top: 52px;
	left: 640px;
	width: 560px;
	height: 455px;
	background: #f8f8f8;
} */

.Electricity_cont_item2 {
	position: relative;
	display: flex;
	margin-top: 80px;
}

.Electricity_cont_item2 img {
	margin-top: 52px;
}

.Electricity_cont_item2_text {
	position: absolute;
	top: 52px;
	left: 640px;
	width: 560px;
	height: 455px;
	background: #f8f8f8;
}
.Electricity_cont_item2_text > h1 {
	margin-top: 76px;
	margin-left: 52px;
	font-size: 24px;
	font-family: PingFangSC-Medium, PingFang SC;
	font-weight: 500;
	color: #444444;
	line-height: 33px;
}
.Electricity_cont_item2_text p {
	margin-top: 19px;
	margin-left: 52px;
	width: 455px;
	height: 256px;
	font-size: 16px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #666666;
	line-height: 32px;
}
.Electricity_cont_item3 {
	position: relative;
	display: flex;
	margin-top: 137px;
}
.Electricity_cont_item3 img {
	position: absolute;
	right: 0;
}
.Electricity_cont_item3_text {
	position: absolute;
	top: 0px;
	width: 560px;
	height: 400px;
	background: #f8f8f8;
}
.Electricity_cont_item3_text > h1 {
	margin-top: 76px;
	margin-left: 52px;
	font-size: 24px;
	font-family: PingFangSC-Medium, PingFang SC;
	font-weight: 500;
	color: #444444;
	line-height: 33px;
}
.Electricity_cont_item3_text p {
	margin-top: 19px;
	margin-left: 52px;

	width: 455px;
	height: 256px;
	font-size: 16px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #666666;
	line-height: 32px;
}

/* 4 */
.Electricity_cont_item4 {
	position: relative;
	display: flex;
	margin-top: 570px;
}
.Electricity_cont_item4_text {
	position: absolute;
	top: 0px;
	left: 640px;
	width: 560px;
	height: 471px;
	background: #f8f8f8;
}
.Electricity_cont_item4_text > h1 {
	margin-top: 76px;
	margin-left: 52px;
	font-size: 24px;
	font-family: PingFangSC-Medium, PingFang SC;
	font-weight: 500;
	color: #444444;
	line-height: 33px;
}
.Electricity_cont_item4_text p {
	margin-top: 19px;
	margin-left: 52px;

	width: 455px;
	height: 256px;
	font-size: 16px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #666666;
	line-height: 32px;
}
</style>

<template>
	<!-- star -->

	<div class="youjiaopage_wrap">
		<nav2></nav2>
		<banner2 :bannerH="h1" :bannerP="P1" :bannerImg="Img1"></banner2>
		<!-- 面包屑 -->
		<div class="Crumbs">
			<!-- <div>当前位置：首页>积分商城><span>金融</span></div> -->
		</div>
		<!-- 电商板块 -->
		<div class="Electricity">
			<div class="Electricity_cont">
				<!-- <h1>电商板块</h1> -->
				<div class="Electricity_cont_item">
					<div class="Electricity_cont_item1" id="sector">
						<div class="Electricity_cont_item1_text">
							金融行业数字化转型
						</div>
						<div class="Electricity_cont_item2_text">
							将金融服务覆盖用户生活等高频场景，多方位触达客户需求
						</div>
						<div >
							<img @dragstart.prevent @contextmenu.prevent class="img"
								src="../../assets/img/bootmall/jinrong_1.png"
								alt=""
							/>
						</div>
					</div>
					<div class="Electricity_cont_item3">
						<p class="Electricity_cont_item3_text">
							借力高频场景，巧妙切入营销
						</p>
						<div class="Electricity_cont_item4_text">
							营销策略与高频场景联动，挖掘生活消费、热门节日等场景元素，通过用户熟悉的场景触达，提升金融产品的受欢迎程度，同时促进用户活跃程度，提升留存转化
						</div>
						<div>
							<img @dragstart.prevent @contextmenu.prevent src="../../assets/img/bootmall/jifenduobao.png" alt="" />
							<!-- <img @dragstart.prevent @contextmenu.prevent src="../../assets/img/bootmall/tianmidalibao.png" alt="" />
							<img @dragstart.prevent @contextmenu.prevent src="../../assets/img/bootmall/jifennongchang.png" alt="" />
							<img @dragstart.prevent @contextmenu.prevent src="../../assets/img/bootmall/jinrong_2_2.png" alt="" /> -->
							<!-- <section class="pc-banner">
								<div class="swiper-container">
									<div class="swiper-wrapper" style="display: flex;align-items: center;">
										<div class="swiper-slide"><img src="../../assets/img/bootmall/jifenduobao.png" alt=""></div>
										<div class="swiper-slide"><img src="../../assets/img/bootmall/tianmidalibao.png" alt=""></div>
										<div class="swiper-slide"><img src="../../assets/img/bootmall/jifennongchang.png" alt=""></div>
										<div class="swiper-slide"><img src="../../assets/img/bootmall/jinrong_2_2.png" alt=""></div>
									</div>
								</div>
								<div class="swiper-pagination"></div>
								<div class="button">
									<div class="swiper-button-prev">
									</div>
									<div class="swiper-button-next">
									</div>
								</div>
							</section> -->
						</div>
					</div>
					<div class="Electricity_cont_item1">
						<div class="Electricity_cont_item1_text">
							积分兑换丰富多样，趣味挑战引力十足
						</div>
						<!-- <div class="Electricity_cont_item2_text">
							根据业务场景制定营销节点专属方案，轻松带来用户增长
						</div> -->
						<div>
							<img @dragstart.prevent @contextmenu.prevent class="img"
								src="../../assets/img/bootmall/jinrong_3.png"
								alt=""
							/>
						</div>
					</div>
					<div class="Electricity_cont_item1">
						<div class="Electricity_cont_item1_text">
							精准营销高效引流，轻松做好用户增长
						</div>
						<div class="Electricity_cont_item2_text">
							将节日热点及传统文化相结合，演绎品牌营销新可能
						</div>
						<div class="box">
							<ul class="list">
								<li><img @dragstart.prevent @contextmenu.prevent class="pic" src="../../assets/img/bootmall/chunjie_0.png" alt=""/></li>
								<!-- <li><img @dragstart.prevent @contextmenu.prevent class="pic" src="../../assets/img/bootmall/nvshengjie_1.png" alt=""/></li> -->
								<li><img @dragstart.prevent @contextmenu.prevent class="pic" src="../../assets/img/bootmall/qingmingjie_2.png" alt=""/></li>
								<li><img @dragstart.prevent @contextmenu.prevent class="pic" src="../../assets/img/bootmall/laodongjie_3.png" alt=""/></li>
								<li><img @dragstart.prevent @contextmenu.prevent class="pic" src="../../assets/img/bootmall/muqingjie_4.png" alt=""/></li>
								<li><img @dragstart.prevent @contextmenu.prevent class="pic" src="../../assets/img/bootmall/duanwujie_5.png" alt=""/></li>
								<li><img @dragstart.prevent @contextmenu.prevent class="pic" src="../../assets/img/bootmall/zhongqiujie_6.png" alt=""/></li>
								<li><img @dragstart.prevent @contextmenu.prevent class="pic" src="../../assets/img/bootmall/guoqingjie_7.png" alt=""/></li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>

		<footer2></footer2>

		<!-- end -->
	</div>
</template>

<script>
import footer2 from "../../components/footer.vue";
import banner2 from "../../components/banner.vue";
import nav2 from "../../components/nav.vue";
// import Swiper from 'swiper'
// import 'swiper/dist/css/swiper.min.css'

export default {
	name: "keji",
	components: {
		footer2,
		nav2,
		banner2,
	},
	data() {
		return {
			h1: "金融行业数字化解决方案",
			P1: "搭建积分运营体系持续维持会员活跃，多维度建立数字化营销，快速获客，强化品牌IP认同，挖掘用户存量价值",
			Img1: require('@/assets/img/bootmall/jinrong.png'),
			sliderIndex: 0,
			images: [  
				{ src: '@/assets/img/bootmall/jifenduobao.png' },  
				{ src: '@/assets/img/bootmall/tianmidalibao.png' },  
				{ src: '@/assets/img/bootmall/jifennongchang.png' },  
				{ src: '@/assets/img/bootmall/jinrong_2_2.png' },  
			],
		};
	},
    mounted() {
        // this.Lunbo()
    },
	methods: {
        // Lunbo() {
        //     var swiper = new Swiper('.swiper-container', {
        //         slidesPerView: 3,
        //         breakpoints: {
        //             750: {
        //                 slidesPerView: 1,
        //             },
        //             990: {
        //                 slidesPerView: 2,
        //             }
        //         },
        //         spaceBetween: 0,
        //         loop: true,
        //         speed: 1000,
        //         autoplay: true,
        //         autoplayDisableOnInteraction: false,
        //         disableOnInteraction: true,
        //         observer: true,
        //         observeParents: true,
        //         pagination: '.swiper-pagination',
        //         paginationClickable: true,
        //         mousewheelControl: true,
        //         debugger: true,
        //         navigation: {
        //             nextEl: '.swiper-button-next',
        //             prevEl: '.swiper-button-prev',
        //         }
        //     });
        // },
    }
};
</script>
<style scoped>
@charset "utf-8";
/*=========================Reset_start==========================*/
body,
h1,
h2,
h3,
h4,
h5,
h6,
div,
p,
dl,
dt,
dd,
ol,
ul,
li,
form,
table,
th,
td,
a,
img,
span,
strong,
var,
em,
input,
textarea,
select,
option {
	margin: 0;
	padding: 0;
}
/*去内外边距*/
html,
body {
	font-family: "微软雅黑", "宋体", Arail, Tabhoma;
	text-align: left;
}
/*字体 文本居左*/
ul {
	list-style: none;
}
/*去ul li 小点*/
img {
	border: 0 none;
}
/*img IE6 兼容问题*/
input,
select,
textarea {
	outline: 0;
}
/*去除点击蓝框*/
textarea {
	resize: none;
	overflow: auto;
}
/*锁定多行文本框大小 与文字超出出现滚动条*/
table {
	border-collapse: collapse;
	border-spacing: 0;
}
/*表格边框合并 边框间距0*/
th,
strong,
var,
em {
	font-weight: normal;
	font-style: normal;
}
/*特殊文字粗细 样式显示正常*/
a {
	text-decoration: none;
}
/*a标签去下划线*/
.clearfix:after {
	clear: both;
	content: "";
	display: block;
	height: 0;
}
/*清浮动*/
a {
	color: #000000;
}
/*==========================Reset_End===========================*/

.youjiaopage_wrap {
	width: 100vw;
	margin: 0 auto;
	height: 1000px;
}
/* 面包屑 */
.Crumbs {
	padding-top: 60px;
	width: 1200px;
	height: 46px;
	margin: 0 auto;
}
.Crumbs {
	font-size: 12px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #999999;
	line-height: 17px;
}
.Crumbs div {
	line-height: 46px;
}
.Crumbs span {
	color: #000000;
}
/* 电商板块 */
.Electricity_cont {
	width: 1200px;
	margin: 0 auto;
	height: 3000px;
	text-align: center;
}

.Electricity_cont_item1 {
	width: 100%;
	min-width: 1200px;
	height: 700px;
	min-height: 700px;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	border-top: 1px solid hsla(0,0%,91%,.5);
}

.Electricity_cont_item1_text {
	margin: 53px 0 20px;
	width: 100%;
	height: 48px;
	font-size: 34px;
	font-weight: 500;
	color: #2d2d2d;
}

.Electricity_cont_item2_text {
	padding: 0 60px;
	margin-bottom: 60px;
	width: 1159px;
	min-height: 28px;
	font-size: 20px;
	font-weight: 400;
	color: #2d2d2d;
	line-height: 28px;
}

.pic {
	width: 200px;
	height: 430px;
}
.box {
	margin-top: 100px;
	margin-left: 150px;
	position: absolute;
	/* 让整个盒子居中 */
	transform: translate(-10%, 20%);
	width: 1000px;
	height: 450px;
	/* 溢出隐藏 */
	overflow: hidden;
}
 
.list {
	margin-left: 300px;
	margin-right: 400px;
    position: absolute;
	/*  top: 0;
		left: 0; */
	/* 设置ul更大的宽度,让子元素li可以并排浮动 */
	width: 4000px;
	height: 450px;
}
 
 .list li {
	/* 让每一个li标签并排浮动 */
	margin-left: 30px;
	float: left;
	width: 300px;
	height: 450px;
	/* 使用animation标签，设置动画名称 时间 循环次数（此处为无限循环） 匀速 */
	animation: move 10s infinite linear;
}
 
        /* 定义动画 */
@keyframes move {
 
             /* 第1张 */
            0% {
                transform: translateX(0px);
            }
			
			/* 第2张 */
            10% {
                transform: translateX(-300px);
            }
            /* 设置同样的值让图片停留一会 */
            20% {
                transform: translateX(-300px);
            }
			
			/* 第3张 */
			25% {
				transform: translateX(-600px);
			}
            35% {
                transform: translateX(-600px);
            }
 
			/* 第4张 */
            40% {
                transform: translateX(-900px);
            }
            50% {
                transform: translateX(-900px);
            }
 
			/* 第5张 */
            55% {
                transform: translateX(-1200px);
            }
			65% {
				transform: translateX(-1200px);
			}
			
			/* 第6张 */
			70% {
				transform: translateX(-1500px);
			}
			80% {
				transform: translateX(-1500px);
			}
			
			/* 第7张 */
			90% {
				transform: translateX(-1800px);
			}
			100% {
				transform: translateX(-1800px);
			}

        }

.Electricity_cont_item3 {
	width: 613px;
    height: 706px;
    padding-right: 80px;
    flex-direction: column;
    justify-content: space-between;
    color: #2d2d2d;
    text-align: left;
}
.Electricity_cont_item3 p {
	margin-top: 200px;
}
.Electricity_cont_item3 img{
    position: absolute;
    inset: 0px;
    /* box-sizing: border-box; */
    /* padding: 0px; */
    border: none;
    /* margin: auto; */
    display: block;
    /* width: 400px; */
    /* height: 300px; */
	margin-top: 1400px;
	padding-left: 950px;
	width: 560px;
	height: 560px;
    /* width: 100%;   */
}
.Electricity_cont_item3_text {
	width: 100%;
    height: 48px;
    font-size: 34px;
    font-weight: 500;
    color: #2d2d2d;
	padding-top: 200px;
}
.Electricity_cont_item4_text {
	width: 100%;
    height: 84px;
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
	margin-top: 20px;
}


.pc-banner {
    position: relative;

    .swiper-container {
        width: 90%;
    }

    .button {
        width: 100%;
        display: flex;
        justify-content: space-between;
        position: absolute;
        top: 50%;

        .swiper-button-next {
            color: black;
        }
    }
}

@media only screen and (min-width:1200px) {
    .main {
        background: springgreen;
    }
}

/*最大的宽度是1200px，小于1200px都适用于这里*/
@media only screen and (max-width:960px) {
    .main {
        width: 100%;
    }
}

/*
       大于min-width的宽度，小于max-width宽度
       * */
@media only screen and (min-width: 960px) and (max-width: 1200px) {
    .main {
        width: 100%;
    }
}

.swiper-slide {
    -webkit-transition: transform 1.0s;
    -moz-transition: transform 1.0s;
    -ms-transition: transform 1.0s;
    -o-transition: transform 1.0s;
    /* -webkit-transform: scale(0.7); */
    /* transform: scale(0.7); */
}


.swiper-slide-active {
    -webkit-transform: scale(1);
    transform: scale(1);
}


.none-effect {
    -webkit-transition: none;
    -moz-transition: none;
    -ms-transition: none;
    -o-transition: none;
}

.swiper-slide {
    img {
        width: 100%;
        display: block;
    }
}

.button {
    width: 100%;
    margin: auto;
    position: relative;
    top: 25%;
}

@media screen and (max-width: 750px) {
    .button {
        width: 100%;
    }
}

.swiper-button-prev {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l4.2%2C4.2L8.4%2C22l17.8%2C17.8L22%2C44L0%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E") #c9c9ca center 50%/50% 50% no-repeat;
}

.swiper-button-next {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L5%2C44l-4.2-4.2L18.6%2C22L0.8%2C4.2L5%2C0z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E") #c9c9ca center 50%/50% 50% no-repeat;
}

@media screen and (max-width: 750px) {
    .button div {
        width: 28px;
        height: 28px;
    }
}
</style>

// 导入封装好的axios
import request from '@/utils/request';
 
// 获取商品列表
export function getList(data) {
  return request({
    url: '/api/front/products',
    method: 'get', 
    data   
  });
}
 // 获取商品详情
export function getProductDetail(id) {
  return request({
    url: '/api/front/product/detail/' + id,
    method: 'get'   
  });
}
// 获取当前列表信息'product/detail/' + id + '?type=' + type
export function getCategory(data) {
  return request({
    url: '/api/front/category', 
    method: 'get',
    data
  })
}
<template>
  <!-- star -->
  <div class="kejipage_wrap">
    <!-- <banner2 :bannerH="h1" :bannerP="P1" :bannerImg="Img1"></banner2> -->
    <!-- 面包屑 -->
    <div class="Crumbs">
      <div>
        <a href="/index">返回首页</a>><span>企业福采</span>
        <div class="log"><a href="/register" class="register">注册</a>  <a href="/login" class="login">登录</a></div>
      </div>
    </div>
    <!-- nav -->
    <div class="nav">
      <div class="wrap">
        <div class="logo">
          <a href="/index"><img @dragstart.prevent @contextmenu.prevent :src="logoimg" /></a>
        </div>
        <div class="phone">
          <span>企业服务热线：027-87208838</span>
        </div>
      </div>
    </div>
    <div class="commod_bg">
      <!-- <div class="container container-1200 nav-tab-content">
				<a href="/spu/list" class="active">福利卡券</a>
				<span class="tab-line">|</span>
				<a href="/cpCategory/index">实物集采</a>
			</div> -->
      <!-- 新增tab 以及广告位 2023.8.16 -->
      <!-- commod_par1 -->
      <div class="commod_par1">
        <div class="commod_par1_tab m-tabs m-tabs-default j-tabs">
          <div class="m-tabs-nav-wrap">
            <a href="javascript:;" class="prev_tab" style="display: none;">﹤</a>
            <nav class="page_tab_content" style="margin-left: 0px;">
              <div class="m-tabs-nav" id="tabs_nav">
<!-- 
                <div class="m-tabs-tab" v-for="(item, index) in productslist" :key="index"
                  :class="{ active: currentClass == index }" @click="toggleTab(index)"> {{ item.id }}</div> -->
                <div class=" m-tabs-tab active" cid="71">福采商品列表</div> 
								<!-- <div class="m-tabs-tab" cid="85">国庆套餐</div>
								<div class="m-tabs-tab" cid="104">高温福利</div>
								<div class="m-tabs-tab" cid="108">通用礼赠</div>
								<div class="m-tabs-tab" cid="100">六一套餐</div>
								<div class="m-tabs-tab" cid="91">佳人套餐</div>
								<div class="m-tabs-tab" cid="95">端午套餐</div>
								<div class="m-tabs-tab" cid="115">新年福利</div>
								<div class="m-tabs-tab" cid="60">生日套餐</div>
								<div class="m-tabs-tab" cid="15">通用套餐</div>
								<div class="m-tabs-tab" cid="59">五一套餐</div> -->
              </div>
            </nav>
            <a href="javascript:;" class="next_tab" style="display: none;">﹥</a>
          </div>
          <div class="m-tabs-content">
            <div class="m-tabs-tabPane active">
              <span>系列：</span>
              <div class="m-tabs-tabPane_div m-tabs-tabPane_third">
                <a href="javascript:;" class="active" cid="71">全部</a>
              </div>
              <div class="m-tabs-tabPane_line"></div>
              <span>类型：</span>
              <div class="m-tabs-tabPane_div m-tabs-tabPane_type">
                <a href="javascript:void(0)" class="active" tid="">全部</a>
                <a href="javascript:void(0)" tid="1">实体卡</a>
                <a href="javascript:void(0)" tid="2">电子卡</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- commod_par1 -->
      <!-- commod_par2   v-show="currentTab == index"-->
      <div class="commod_par2">
        <ul>
          <li v-for="(item, index) in productslist" :key="index" @click="goodInfo(item.id)">
            <a href="javascript:(0)">
              <div class="commod_par2_img ind_par2_img">
                <img @dragstart.prevent @contextmenu.prevent :src="item.image">
              </div>
              <div class="commod_par2_title">
                <p>{{item.storeName}}</p><span><sub>¥</sub>{{item.price}}-{{item.otPrice}}</span>
              </div>
            </a>
          </li>
          <!-- <div class="g-ctrn"  v-show="products.list<=0">
            <div class="m-error-page"> <i class=""> <img @dragstart.prevent @contextmenu.prevent src="https://cardmall-online.guanaitong.com/images/lower/lower1.png"></i>
              <div class="desc">敬请期待</div> 
            </div>
          </div> -->
        </ul>
        <div class="clearfix not_page">
          <div id="pager1" class="u-page simple"></div>
        </div>
      </div>
    </div>

    <!-- footer -->
    <footer2></footer2>

    <!-- end -->
  </div>
</template>

<script>
import footer2 from "../components/footer.vue";
import { getList, getCategory} from '@/api/example';
// import banner2 from "../components/banner.vue";
// import Pages from "../components/recruitInfoPages.vue"
// import nav2 from "../components/nav.vue";
export default {
	name: "keji",
	components: {
		footer2,
		// nav2,
		// banner2,
		// Pages
	},
	data() {
		return {
			h1: "企业福采",
			P1: "帮助品牌方打通销售通路，提高数字商品流通效率；为消费端构建权益场景，提升用户活跃！",
			Img1: this.$imgUrl+"/yuyua/img/keji/20230705162508.jpg",
			logoimg: this.$imgUrl+"/yuyua/img/YUYUAN.png", 
			currentTab: 0,
            currentClass: 0,
			tabList: ['firstTab', 'secondTab', 'thirdTab'],
			goodList: [
				{
					id:1,
					title: "【实体卡】2023中秋福利套餐.月满中秋",
					price: 50.00,
					price2:1000.00,
					href:"",
					img:"https://file.guanaitong.com/grus-gfs/product/mgr-gateway-common/by-days/2023-07-27/46b744b719cb31ac7f954931841b836b.jpg?x-oss-process=image/resize,p_50"
				},
				{
					id:2,
					title: "【实体卡】2023中秋福利套餐·中秋月",
					price: 50.00,
					price2:1000.00,
					href:"",
					img:"https://file.guanaitong.com/grus-gfs/product/mgr-gateway-common/by-days/2023-07-27/46b744b719cb31ac7f954931841b836b.jpg?x-oss-process=image/resize,p_50"
				},
				{
					id:3,
					title: "【实体卡】2023中秋福利套餐·月野大作战",
					price: 50.00,
					price2:1000.00,
					href:"",
					img:"https://file.guanaitong.com/grus-gfs/product/mgr-gateway-common/by-days/2023-07-27/46b744b719cb31ac7f954931841b836b.jpg?x-oss-process=image/resize,p_50"
				},
				
				{
					id:4,
					title: "电子卡】2023中秋福利套餐.月满中秋",
					price: 50.00,
					price2:1000.00,
					href:"",
					img:"https://file.guanaitong.com/grus-gfs/product/mgr-gateway-common/by-days/2023-07-27/46b744b719cb31ac7f954931841b836b.jpg?x-oss-process=image/resize,p_50"
				},
				{
					id:5,
					title: "【电子卡】2023中秋福利套餐·中秋月",
					price: 50.00,
					price2:1000.00,
					href:"",
					img:"https://file.guanaitong.com/grus-gfs/product/mgr-gateway-common/by-days/2023-07-27/46b744b719cb31ac7f954931841b836b.jpg?x-oss-process=image/resize,p_50"
				},	
				{
					id:6,
					title: "【电子卡】2023中秋福利套餐·中秋月",
					price: 50.00,
					price2:1000.00,
					href:"",
					img:"https://file.guanaitong.com/grus-gfs/product/mgr-gateway-common/by-days/2023-07-27/46b744b719cb31ac7f954931841b836b.jpg?x-oss-process=image/resize,p_50"
				},
			],
      productslist: []
    };
	},
  created() {
    this.getlist();console.log(localStorage.getItem("cart"));
    // this.productslist = [];
	},   
	methods: {
    getlist: function() {
    // let that=this
    getCategory().then((res) =>{
        console.log(res)
        })
      getList().then((res) =>{
  
      // 拿到数据后给参数赋值并展示
      // if(res.Flag){
        this.productslist = res.data.list
        // that.$set(that, 'productslist', res.data.list);
      // } else {
      //   // 输出错误
      //   this.$alert.error(res.Message);
      // }
      
    })
  },
		toggleTab(current) {
            this.currentTab = current;
            this.currentClass = current;
        },
        goodInfo(index){
          this.$router.push("/goodInfo/" + index);
        }
	}
};
</script>

<style scoped>
@charset "utf-8";
/*=========================Reset_start==========================*/
body,
h1,
h2,
h3,
h4,
h5,
h6,
div,
p,
dl,
dt,
dd,
ol,
ul,
li,
form,
table,
th,
td,
a,
img,
span,
strong,
var,
em,
input,
textarea,
select,
option {
	margin: 0;
	padding: 0;
}
/*去内外边距*/
html,
body {
	font-family: "微软雅黑", "宋体", Arail, Tabhoma;
	text-align: left;
}
/*字体 文本居左*/
ul {
	list-style: none;
}
/*去ul li 小点*/
img {
	border: 0 none;
}
/*img IE6 兼容问题*/
input,
select,
textarea {
	outline: 0;
}
/*去除点击蓝框*/
textarea {
	resize: none;
	overflow: auto;
}
/*锁定多行文本框大小 与文字超出出现滚动条*/
table {
	border-collapse: collapse;
	border-spacing: 0;
}
/*表格边框合并 边框间距0*/
th,
strong,
var,
em {
	font-weight: normal;
	font-style: normal;
}
/*特殊文字粗细 样式显示正常*/
a {
	text-decoration: none;
}
/*a标签去下划线*/
.clearfix:after {
	clear: both;
	content: "";
	display: block;
	height: 0;
}
/*清浮动*/
a {
	color: #000000;
}
/*==========================Reset_End===========================*/

.kejipage_wrap {
	width: 100vw;
	margin: 0 auto;
	height: 1000px;
}
/* 面包屑 */
.Crumbs {
	width: 1200px;
	height: 46px;
	margin: 0 auto;
}
.Crumbs {
	font-size: 12px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #999999;
	line-height: 17px;
}
.Crumbs div {
	line-height: 46px;
}
.Crumbs div a{

	color: #999;
	cursor: pointer;
}
.Crumbs span {
	color: #000000;
}

.Crumbs .log{
  float:right;
  cursor:pointer;
  color:#666;
}
.Crumbs .log .register{
  margin-right:5px
}

.Crumbs .log .register:hover{
  color:#feb23e;
}
.Crumbs .log .login:hover{
  color:#feb23e;
}
.nav{
	width: 100vw;
	box-shadow: 0px 0px 3px rgba(155, 155, 155, 0.1);

}
.wrap {
	width: 1200px;
	height: 101px;
	line-height: 101px;
	margin: 0 auto;
}

.wrap > .logo a > img {
	/* position: absolute; */
	/* left: 190px; */
	float: left;
	margin-top: 23px;
	width: 181px;
	/* height: 54px; */
}
.wrap .phone{
	/* width: 400px; */
	height: 101px;
	text-align: right;
}
/* 商品列表 */
.page_tab_content{width:100000px;}

.m-tabs-nav{float:left}
.m-tabs-nav div{
  display: inline-block;
  zoom: 1;
}

.commod_par1 .m-tabs-nav-wrap .prev_tab {
  position: absolute;
  top: 0;
  left: 0;
  width: 35px;
  height: 35px;
  box-shadow: 0 0 3px #c6c6c7;
  background: #fff;
  font-family: '宋体';
  font-size: 25px;
  text-align: center;
  line-height: 35px;
  color: #999;
  display: none
}
.commod_par1 .m-tabs-nav-wrap .next_tab {
  position: absolute;
  top: 0;
  right: 0;
  width: 35px;
  height: 35px;
  box-shadow: 0 0 3px #c6c6c7;
  background: #fff;
  font-family: '宋体';
  font-size: 25px;
  text-align: center;
  line-height: 35px;
  color: #999;
  display: none
}
.commod_bg{
  width: 100%;
  min-width: 1200px;
  background: #f7f7f7;
  padding-top: 25px;
  padding-bottom: 25px;

}
/* commod_par1 */
.commod_par1{
  width: 1200px;
  margin: 0 auto;
  background:#fff;
  box-shadow: 0px 0px 3px rgba(155, 155, 155, 0.1);
  padding: 20px;
}
.commod_par1_tab>.m-tabs-nav-wrap>.page_tab_content>.m-tabs-nav>.m-tabs-tab.active{
  border: 0 none;
  background: none;
  margin: 0;
  padding:0 20px;
  border-bottom: 3px solid #ffb74d;
  color: #ffb74d;
}
.commod_par1_tab>.m-tabs-nav-wrap>.page_tab_content>.m-tabs-nav>.m-tabs-tab{
  border: 0 none;
  background: none;
  padding: 0;
  color: #666;
  font-size: 14px;
  padding:0 20px;
  height: 35px;
  line-height: 35px;
  display: table-cell;
  cursor: pointer;
}
.commod_par1_tab>.m-tabs-nav-wrap{
  /* height: 35px;
  line-height: 35px; */
  overflow: hidden;
  border-bottom: 0 none;
  position: relative;
}
.commod_par1_tab>.m-tabs-content{
  border: 0 none;
  border-top: 1px solid #f7f7f7;
  padding: 0;
  padding-top: 11px;
}
.commod_par1_tab .m-tabs-tabPane{
  font-size: 0px;
}
.commod_par1_tab .m-tabs-tabPane a{
  display: inline-block;
  
  zoom: 1;
  font-size: 14px;
  color: #333333;
  margin-right:45px;
  height: 42px;
  line-height: 42px;

}
.commod_par1_tab .m-tabs-tabPane a:hover,.commod_par1_tab .m-tabs-tabPane .active{
  color: #ffb74d;
}
/* commod_par1 */
/* commod_par2 */
.commod_par2{
  width: 1200px;
  margin: 0 auto;
  background:#fff;
  box-shadow: 0px 0px 3px rgba(155, 155, 155, 0.1);
  padding: 20px;
  margin-top: 25px;
}
.commod_par2_img{
  width: 276px;
  height: 276px;
  overflow: hidden;
}
.commod_par2_img img{
  width: 100%;
}
.commod_par2_title{
  font-size: 14px;
  color: #000000;
  padding: 0 25px;
  margin: 0 auto;
  text-align: center;
  height: 90px;
  padding-top: 20px;
  background: #fff;
  transition:all 0.5s;
  -moz-transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;

}
.commod_par2_title p{
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  transition:all 0.5s;
  -moz-transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
}
.commod_par2_title span{
  color: #ee8028;
  display: block;
  margin-top: 5px;
  font-weight: bold;
  font-size: 18px;
  margin-left: 6px;
}
.commod_par2_title span sub{
  font-size: 12px;
  font-weight: normal;
  margin-right: 2px;
}
.commod_par2 ul{
  font-size: 0px;
}
.commod_par2 ul li{
  display: inline-block;
  
  zoom: 1;
  width: 276px;
  margin:0 9px;
  margin-bottom: 20px;
  transition:all 0.5s;
  -moz-transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;

}
.commod_par2 ul li:hover {
  box-shadow:0px 0px 12px rgba(155, 155, 155, 0.32)
}
.commod_par2 ul li:hover .commod_par2_title{
  background: #f7f7f7;

}
.commod_par2 ul li:hover .commod_par2_title p{
  color: #FFB74D;

}

.g-ctrn {
    min-height: 450px;
    padding-bottom: 50px;
    display: inline-block;
    width: 100%
}

.g-ctrn .m-error-page,.g-ctrn .m-result {
    padding-top: 100px
}
/* 图片加载 */
.lazy_Bg{
  width: 100%;
  height: 100%;
  /* background: url(../images/public/load.jpg) no-repeat center center; */
}
/* 图片加载 */
/* commod_par2 */
/* 商品列表 */
/* 面包屑 */
.set_bread{
  width: 1200px;
  margin: 0 auto;
  margin-top: 60px;
}
.m-bread{ display: block; font-size: 12px; color: #333333; }
.m-bread .gatling-icon{
  font-size: 12px;
  color: #333;
}
.m-bread a{ color: #333; }
.m-bread a:hover{
  color: #FFB74D;
}
.m-bread .cur{
  color: #333;
  width: 220px;
  display: inline-block;
  
  zoom: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  vertical-align: middle;
}
.m-bread .name{
  margin-right: 0px;;
}

/* 面包屑 */
/* par1 */
.det_par1{
  width: 1200px;
  margin: 0 auto;
  margin-top: 25px;
}
.det_par1_L{
  width: 438px;
}


.jqzoom{float:left;border:none;position:relative;padding:0px;cursor:pointer;margin:0px;display:block;}
.zoomdiv{z-index:100;position:absolute;top:0px;left:0px;width:438px;height:438px;background:#ffffff;display:none;text-align:center;overflow:hidden;}
.jqZoomPup{z-index:10;visibility:hidden;position:absolute;top:0px;left:0px;width:20px;height:20px;border:1px solid #aaa;background:#ffffff /*url(../images/zoom.png) 50% center no-repeat*/;opacity: 0.5;-moz-opacity: 0.5;-khtml-opacity: 0.5;filter: alpha(Opacity=50);}
.jqzoom_qyzs p{
  /* background: url(../images/details/det1.png) no-repeat center center; */
  width: 110px;
  height: 40px;
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 1;
}
.jqzoom  img{
  width: 438px;
  height: 438px;
}

.spec-preview{width:438px;height:438px;}
.spec-scroll{clear:both;margin-top:15px;width:438px;}

.spec-scroll .items{float:left;position:relative;width:438px;height:78px;overflow:hidden;}
.spec-scroll .items ul{position:absolute;width:999999px;height:78px;}
.spec-scroll .items ul li{float:left;width:78px; margin-right: 13px; }
.spec-scroll .items ul li img{width:72px;height:72px;border: 3px solid #fff;}
.spec-scroll .items ul li img:hover{border:3px solid #ffb74d;}


.det_par1_R{
  width: 702px;
}
.det_par_title{
  font-size: 20px;
  color: #333333;
  line-height: 30px;
  font-weight: bold;
}
.det_par_price{
  width: 705px;
  height: 155px;
  background: #f7f7f7;
  padding: 20px;
  margin-top: 40px;
}
.det_par_price p{
  font-size: 14px;
  color: #333333;
}
.det_par_price p span{
   font-size: 28px;
   color: #ee8028;
   margin-left: 15px;
 }

.exchange_product_price{
  width: 705px;
  height: 80px;
  background: #f7f7f7;
  padding: 20px;
  margin-top: 40px;
}
.exchange_product_price p{
  font-size: 14px;
  color: #333333;
}
.exchange_product_price p span{
  font-size: 28px;
  color: #ee8028;
  margin-left: 22px;
}

.exchange_product_info{
  margin: 20px 0 10px 20px;
}

.exchange_product_info span{
  margin: -4.5px 0 0 23px;
}

.exchange_product_sku{
  margin: 0 0 10px 20px;
}

.exchange_product_label{
  width: 630px;
  display: inline-block;
  
  zoom: 1;
}

.exchange_product_label .u-radio>input{
  cursor: auto;
}

.exchange_product_label .u-checkbox>input{
  cursor: auto;
}


.m-tips{ padding: 5px 10px; background-color: #f8f0e6; border: 1px #F4E0A4 solid; color: #ee8028; font-size: 14px; height: 50px; line-height: 40px; position: relative;}
.det_par_price .m-tips p{color: #ee8028;}
.m-tips .icon{
  float: left;
  margin-right: 6px;
  /* background: url(../images/details/det2.png) no-repeat center center; */
  width: 18px;
  height: 18px;
  top: 9px;
}
.m-tips .desc{ overflow: hidden; }
.m-tips.b0{border-color: #f8f0e6;}
.u-checkbox:before, .u-radio:before{
  /* background: url(../images/details/icon-check.png) no-repeat */
}
.docs-example span{
  font-size: 14px;
  color: #333;
  display: inline-block;
  
  zoom: 1;
  height: 30px;
  line-height: 30px;
  vertical-align: top;
  margin-right: 10px;
}
.u-state-btn.checked{
  border: 1px solid #ffb74d
}
.u-state-btn{
  vertical-align: middle;
  line-height: 30px;
  padding:0 30px;
}
.u-checkbox>input, .u-radio>input{
  cursor: pointer;
}
.det_par1_class{
  margin-top: 25px;
}
.det_par1_class p{
  font-size: 14px;
  color: #333333;
}
.det_par1_class p span{
  font-size: 14px;
  color: #000000;
  margin-left: 15px;
}
.det_par1_jsq_sl{
  font-size: 14px;
  color: #333;
  float: left;
  height: 26px;
  line-height: 26px;
  margin-right: 15px;
}
.j-amount{
  float: left;
  margin-right: 10px;
}
.det_par1_jsq_kc{
  font-size: 12px;
  color: #666666;
  height: 26px;
  line-height: 26px;

}
.det_par1_jsq{
  margin-top: 25px;
  height: 50px;
}
.det_par1_p{
  font-size: 12px;
  color: #de0303;
  margin-top: 5px;
  padding-left: 43px;
}
.u-btn{
  border-radius: 0px;
  margin-right: 30px;
  height: 48px;
  line-height: 48px;
  font-size: 18px;
}
.u-btn.u-btn-default-ghost{
  color: #ee8028;
  background: #fbf7f2;
  border: 1px solid #ee8028
}
.u-btn.u-btn-default-ghost:hover{
  background: #fff;
  border: 1px solid #ee8028
}
.u-btn.u-btn-default-ghost.disabled, .u-btn.u-btn-default-ghost.disabled:hover {
  background-color: #ededed;
  border-color: #ededed;
  color: #ccc;
  text-decoration: none;
  cursor: default;
}
.u-btn.u-btn-primary{
  background: #ee8028;
  border: 1px solid #ee8028
}
.u-btn.u-btn-primary:hover{
  background: #f46f04;
  border: 1px solid #f46f04
}

.u-btn.u-btn-primary.disabled, .u-btn.u-btn-primary.disabled:hover {
  background-color: #ededed;
  border-color: #ededed;
  color: #ccc;
  text-decoration: none;
  cursor: default;
}

.det_icn{
  display: inline-block;
  
  zoom: 1;
  height:23px;
  width:28px;
  /* background: url(../images/details/det_icn.png) no-repeat center center; */
  vertical-align: text-bottom;
  margin-right: 5px;
}
/* par1 */
/* par2 */
.line{
  width: 1200px;
  margin: 0 auto;
  margin-top: 90px;
}
.det_title{
  font-size: 20px;
  color: #333333;
  text-align: center;
  font-weight: bold;
  margin-top: 30px;
}
.det_title p{
  font-size: 14px;
  color: #999999;
  font-weight: normal;
}
.det_pro_list{
  width: 1200px;
  margin: 0 auto;
  margin-top: 25px;
}
.det_pro_lis_class{
  font-size: 0px;
  text-align: center;
}
.det_pro_lis_class a{
  font-size: 14px;
  color: #333333;
  display: inline-block;
  
  zoom: 1;
  margin: 0 23px;
}
.det_pro_lis_class a:hover,.det_pro_lis_class .active{
  color: #ffb74d;
}
.det_pro_img{
  width: 285px;
  height: 285px;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  align-items: center;
  -webkit-align-items:center;
  justify-content: center;
  -webkit-justify-content: center;
}
.det_pro_img img{
  width: 100%;
}
.det_pro_title{
  font-size: 14px;
  color: #000000;
  padding: 0 25px;
  margin: 0 auto;
  text-align: center;
  height: 90px;
  padding-top: 30px;
  background: #fff;
  transition:all 0.5s;
  -moz-transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;

}
.det_pro_title p{
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  transition:all 0.5s;
  -moz-transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
}
.det_pro_title span{
  color: #ee8028;
  display: block;
  margin-top: 5px;

}
.det_pro{
  margin-top: 60px;
  /*height: 1155px;*/
  overflow: hidden;
  transition:all 0.5s;
  -moz-transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  position: relative;
}
.det_pro ul{
  font-size: 0px;
  display: flex;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  align-items: center;
  -webkit-align-items:center;
  width: 1240px;
  justify-content: flex-start;
  -webkit-justify-content: flex-start;
}
.det_pro ul li{
  width: 285px;
  margin-bottom: 20px;
  margin-right: 20px;
}
.det_pro ul li:hover {
  box-shadow:0px 0px 12px rgba(155, 155, 155, 0.32)
}
.det_pro ul li:hover .det_pro_title{
  background: #f7f7f7;

}
.det_pro ul li:hover .det_pro_title p{
  color: #FFB74D;

}
.det_mor{
  display: none;
  width: 1200px;
  height: 301px;
  /* background: url(../images/details/bet_3.png) repeat-x center center; */
  font-size: 14px;
  color: #999999;
  margin-top: -280px;
  padding-top: 275px;
  text-align: center;
  cursor: pointer;
  position: absolute;
  left: 0px;
  bottom: 0px;
  z-index: 1;
}
.det_pro_mor{
  height: 1155px;
}
.det_mor_cat{
  height: 30px;
  /* background: url(../images/details/bet_3.png) repeat-x center bottom; */
  line-height: 30px;
  padding: 0px;
  margin-top: 0px;

}
/* par2 */
/* par3 */
.det_par3_cen{
  width: 1200px;
  margin: 0 auto;
  margin-top: 60px;
}

/* par3 */

/* par4 */
.det_par4_cen{
  width: 1200px;
  margin: 0 auto;
}
.product-detail-module-title {
  margin-bottom: 38px;
  color: #333;
  text-align: center;
  font-weight: normal;
}
.exchange-ways-container .sub-title, .exchange-notices .sub-title {
  font-size: 18px;
  color: #333;
  font-weight: normal;
  border-bottom: 1px solid #E8E8E8;
  padding-bottom: 4px;
  margin-bottom: 12px;
}
.exchange-notice-list li {
  color: #ec6618;
  height: 40px;
  line-height: 40px;
}


.exchange-notice-list li {
  list-style: disc inside;
}
.exchange-process-info{ padding:0 !important; margin:0 !important;border: 0px solid #ccc !important;}

.kbj_main{ width:1090px; display:block; margin:0 auto; padding:0;}
.product_show{ margin-bottom:26px;}
.kbj_t1{ width:100%; height:140px; display:block; background:#f4f4f4; padding-top:58px;}
.kbj_t1 h1{ text-align:center; font-size:40px; height:40px; line-height:40px; margin:0 0 26px 0px; padding:0; font-weight:normal; }
.kbj_t1 h1 em{background:url(//www.guanaitong.com/nj/content/images/zq-pic/i_bg.png) center no-repeat; font-style:normal; height:20px; width:20px; padding:10px 15px}
.cp-pic01{ width:100%; height:626px; display:block; padding:0; margin:0;}
.cp-pic02{ width:100%; height:402px; display:block; padding:0; margin:0; background:#f4f4f4;}
.cp-pic02 img{ float:left;  height:402px;}
.cp-info{ height:402px;   display: table-cell;vertical-align:middle}
.cp-info h2{height:43px;   line-height:43px; padding-bottom:4px;border-bottom:2px solid #df5417; font-weight:normal; margin:0px 0px 15px 0px; min-width:340px;}
.cp-info h2 strong{ height:39px; font-size:36px; line-height:39px;  border-bottom:4px solid #df5417; margin-right:10px;}
.cp-info h2 em{ height:43px; font-size:22px; line-height:43px;  font-style:normal; }
.cp-info p{ font-size:24px; line-height:34px;color:#454545; padding:0 ;margin:0;}
.cp-info span{ font-size:20px; color:#454545; margin-top:5px; display:block;}
.look-more{ width:100%; height:66px; background:#f4f4f4; }
.look-more a{ margin:0 auto; display:block; font-size:16px; text-align:center; text-decoration:none;}
.look-more a:hover{ color:#c36146;}
.kbj_t1 p{ font-size:24px; display:block; text-align:center; color:#454545; padding:0; margin:0;}
.cs{ color:#df5417;}
.theme_img{float:left; margin-top:90px;}
.product-detail-module-title{padding-top: 70px;padding-bottom: 38px; margin-bottom:0px !important; border:1px solid #E8E8E8;  border-bottom:0px none;}
.content{font-size:28px; color:#000;}
.exchange-ways-container{padding:0 40px; border-left:1px solid #E8E8E8 ;border-right:1px solid #E8E8E8;border-bottom:1px solid #E8E8E8; padding-bottom:42px;}
.sub-title{font-size:18px; color:#000000; height:40px;}
.exchange-ways-container .theme_ul{float:right; width:420px; margin-top:90px;}
.exchange-ways-container .theme_ul li{ padding-bottom:40px;}
.exchange-ways-container .theme_ul li.funv_ewm{
  /* background: url(../images/ewm.jpg) no-repeat right center; */
  padding-right: 100px;
}
.exchange-ways-container .theme_ul li img{ float:left;}
.exchange{ display: table-cell;vertical-align:middle; height:60px; padding-left:20px; }
.exchange p{ margin-bottom:5px;font-size:18px; color:#000000;}
.exchange span{font-size:12px; color:#000; }
.exchange-notices{ margin-top:100px;}
.exchange-notice-list li{color:#ec6618; height:40px; line-height:40px;}
.exchange-notice-list span{color:#000000; font-size:16px;}
.foot_logo{ margin-bottom:150px; margin-top:100px;}

.P_ys{ position:relative;}
.P_ys p{ font-size:44px; color:#fff; font-weight:bold; line-height:57px; position:absolute; left:60px; top:190px; }
.P_ys span{ font-size:24px; color:#fff; position:absolute; left:60px; top:316px;}
.p_why{ padding:35px; background:#585ab0;}
.P_img_1{ position: absolute;left:60px; top:60px;}

.p_why h1{ text-align:center; font-size:40px; height:40px; line-height:40px; margin:0 0 26px 0px; padding:0; font-weight:normal; color:#fff; padding-top:20px; }
.p_why em{background:url(//www.guanaitong.com/nj/content/images/P1.png) center no-repeat; font-style:normal; height:20px; width:20px; padding:10px 15px }
.p_why p{font-size:24px; display:block; text-align:center; color:#fff; padding:0; margin:0; line-height:40px;}
.p_why ul{ padding:28px 0; width:1020px; background:#ffffff; margin-top:55px;font-size:0px; text-align:center;}
.p_why ul li{ width:254px; display:inline-block; zoom:1; text-align:center; border-right:1px dashed #eeeeee; }
.p_why ul .none_bor{ border:0 none;}
.p_why ul li img{ display:block; margin:0 auto;}
.p_why ul li p{ font-weight:bold; font-size:20px; color:#424242; margin-top:15px;}
.p_why ul li span{ font-size:14px; line-height:24px; display:block; color:#424242; margin-top:10px;}

.p_why_sr{ background:#f18208}
.P_ding{ margin-top:1px;}
.p_why_xn{ background: url(//www.guanaitong.com/nj/content/images/xn-pic/xn2.png)}
.kbj_t1_xn{ color: #df5417;}
.P_ys_sr p{top: 100px;}
.P_ys_sr span{top:290px;}
.P_ys_dw p{top:150px;}
.P_ys_dw span{top:280px;}
.p_why_dw{background: #18775b}
.cp-info_dw p{font-size: 16px}
.P_ys_xr p{
  top: 90px;
  font-size: 40px
}
.P_ys_xr span{
  top: 280px
}
.p_why_xr{
  background: #5081bb
}
.cp-info_dw  .lingsasd{
  line-height: 25px;
}
.kbs_img{
  margin: 20px 0;
  display: block;
}
/* par4 */

/* 商品下架 */

.lower_cen p{
  font-size: 24px;
  color: #333333;
  text-align: center;
}
.agr_close{
  display: block;
  margin: 0 auto;
  width: 150px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  font-size: 18px;
  color: #fff;
  background: #ee8028;
  border-radius: 25px;
  margin-top: 30px;
}
.agr_close:hover{
  background: #f46f04;
  text-decoration: none;
}
.m-error-page{
  font-size: 16px;
  color: #999;
  text-align: center;
}
.m-error-page i{
  margin: 0 auto 10px;
  display: block;
}

/* 商品飞入购物车 */
#msg{position:fixed; top:300px; right:35px; z-index:10000; width:1px; height:52px; line-height:52px; font-size:20px; text-align:center; color:#fff; background:#360; display:none}
.u-flyer{display: block;width: 50px;height: 50px;border-radius: 50px;position: fixed;z-index: 9999;}

/* 新加商品分类实体虚拟 */
.commod_par1_tab .m-tabs-tabPane span{
  display: block;
  font-size: 14px;
  color: #333333;
  float: left;
  height: 42px;
  line-height: 42px;
}
.m-tabs-tabPane_div{
  padding-left: 60px;
}
.m-tabs-tabPane_line{
  border-bottom: 1px solid #f7f7f7;
}

/* 2019.12.12定制icn添加 */
.pro_dz{
  margin-top: 13px;
}
.commod_par2_title{
  text-align: start;
}
.commod_par2_title .pro_dz{
  float: right;
  margin-top: 7px;
}
.pro_dz_none{
  display: none;
}
</style>
